<template>
  <div></div>
</template>
<script>
import { WxShare } from "@/libs/WxShare";
import wx from "weixin-js-sdk"; // 使用js-sdk

import { showToast } from "vant";
export default {
  name: "ShareBox",
  props: {
    shareData: {
      type: Object,
      default: false,
    },
  },
  data() {
    return {
      endData: false,
      device: false,
    };
  },
  setup() {},
  mounted() {
    let _this = this;
    this.device = localStorage.getItem("device");

    if (!this.shareData) {
      let tmp = localStorage.getItem("defaultShare")
        ? JSON.parse(localStorage.getItem("defaultShare"))
        : false;
      if (tmp) {
        this.endData = { ...tmp };
      }
    } else {
      this.endData = { ...this.shareData };
    }
    if (!this.endData) {
      return;
    }

    setTimeout(function () {
      if (_this.device == "mini") {
        _this.makeShareForMini();
      } else if (_this.device == "wechat") {
        _this.makeShare();
      } else {
        console.log("不支持");
      }
    }, 200);
  },
  methods: {
    makeShareForMini() {
      // showToast(this.endData.title);
      // 小程序分享
      wx.miniProgram.postMessage({
        data: {
          title: this.endData.title,
          path: this.endData.link ? this.endData.link : location.href,
          imgUrl: this.endData.imgUrl,
        },
      });
    },
    makeShare() {
      let _that = this;
      const wxShare = new WxShare();
      const CURRENT_URL_HISTORY = location.href; //注意我的项目使用的history；
      const CURRENT_URL_HASH = location.href.split("#")[0]; // hash模式使用这个

      const SHARE_CONFIG = {
        title: _that.endData.title,
        desc: _that.endData.desc,
        link: _that.endData.link ? _that.endData.link : CURRENT_URL_HISTORY, // 要分享的页面地址不允许存在#参数
        imgUrl: _that.endData.imgUrl, // 分享出去链接图片
      };
      wxShare.yioksShare(SHARE_CONFIG); // 调用分享
    },
  },
};
</script>
  