import apis from '@/libs/apis'
import wx from 'weixin-js-sdk';	// 使用js-sdk
 
export class WxShare {
 
  signMap = new Map();  // 存储地址url认证信息sign
  // 分享默认参数
  defaultShareConfig = {
    title: '分享标题',
    desc: '描述信息',
    link: location.href,
    imgUrl: 'logo',
    jsApiList: [ 'updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareWeibo', 'onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ' ], // 分享给朋友分享到QQ  分享到朋友圈 分享到腾讯微博
  }
 
  // 分享参数
  wxShareConfig = {}
 
  // 测试方法
  log(val) {
    console.log('this is share', val)
    console.log('defaultShareConfig', this.defaultShareConfig)
  }
 
  /**
   * 
   * @param {Object} config 分页配置信息，包括title，desc，等等；
   */
  yioksShare( config = {}) {
    this.wxShareConfig.title = config.title || this.defaultShareConfig.title;
    this.wxShareConfig.desc = config.desc || this.defaultShareConfig.desc;
    this.wxShareConfig.link = config.link || this.defaultShareConfig.link;
    this.wxShareConfig.imgUrl = config.imgUrl || this.defaultShareConfig.imgUrl;
    this.wxShareConfig.jsApiList = config.jsApiList || this.defaultShareConfig;
 
    const signMsg = this.signMap.has(this.wxShareConfig.link)
    if(signMsg) {
      // 注入签名信息， 并调用分享
      // console.log('调用分享')
      this.injectAndShare(signMsg)
    } else {
      // 当前分享链接不存在签名 = 更新签名信息
      // 获取签名信息
      // console.log('调用获取签名接口')
      this.getShareAuth(this.wxShareConfig.link)
    }
  }
 
 
  /**
   * 
   * @param {Object} signMsg 接口签名信息
   * @desc jssdk签名权限注入，并添加分享事件监听器
   */
  injectAndShare(signMsg) {

    const that = this;
 
    signMsg.timestamp = signMsg.timestamp.toString();
    // 访问页面和获取页面必须保持一致
    // console.log('url 比对结果',  location.href.split('#')[0] === signMsg.url )
    wx.config({
      debug: signMsg.debug,  // 开发期间启用
      appId: signMsg.appId,
      timestamp: signMsg.timestamp.toString(),   // 注意！时间戳这里应该是10位字符串
      nonceStr: signMsg.nonceStr,     // 注意驼峰命名
      signature: signMsg.signature,
      jsApiList: signMsg.jsApiList,
    })
 
    wx.ready(function() {
      const { title,desc,link,imgUrl } = that.wxShareConfig;
      // console.log('wx.ready', '######')
 
      // wx.checkJsApi({
      //   jsApiList:  ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareWeibo', 'onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ' ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
      //   success: function(res) {
      //   // 可用的api值true，不可用为false
      //   // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
      //     console.log('#####checkJsApi#####', res)
      //   }
      // });
 
      wx.updateAppMessageShareData({
        title,desc,link,imgUrl,
        success: function() {
          // console.log('分享成功！')
        },
        cancel: function() {
          // console.log('分享取消！')
        },
        fail: function() {
          // console.log('分享失败！')
        }
      })
 
       wx.updateTimelineShareData({
        title,desc,link,imgUrl,
        success: function() {
          // console.log('分享成功！')
        },
        cancel: function() {
          // console.log('分享取消！')
        },
        fail: function() {
          // console.log('分享失败！')
        }
      })
 
    })
 
    wx.error(function(res) {
      // console.log('wx error:', res)
    })
 
  }
 
  /**
   * 
   * @param {url} shareUrl 
   * @description 从服务器获取分享页面签名信息
   */
  getShareAuth(shareUrl,spm) {
    // console.log('待获取权限的地址', shareUrl)
    apis.makeShareUrl({
        url: shareUrl,
        spm:spm
    }).then(res => {
      // console.log('获取签名信息结果：', res)
      this.signMap.set(shareUrl, res)  // 存储信息
      this.injectAndShare(res);  // 注入签名信息
    }).catch(err => {
      console.error('err', err)
    })
  }
}

