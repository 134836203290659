<template>
  <div class="loads">
    <div class="text" v-if="text">{{ text }}</div>
  </div>
</template>
  
  <script>
export default {
  name: "InLoad",
  props: {
    text: {
      type: String,
      default: "请稍后...",
    },
  },
  data() {
    return {
      tabData: false,
    };
  },
  mounted() {
    this.tabData =
      localStorage.getItem("tabData") &&
      localStorage.getItem("tabData") != undefined
        ? JSON.parse(localStorage.getItem("tabData"))
        : false;
    if (!this.tabData) {
      this.getBars();
    }
  },
  methods: {},
};
</script>
  
<style scoped>
.loads {
  position: relative;
  z-index: 0;
  height: 100vh;
}
.loads::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("/public/img/login_bg.jpg");
  background-position: top center;
  background-size: 100% 100%;
  opacity: 0.2;
}
.text {
  padding-top: 100px;
  height: 50px;
  text-align: center;
  color: #666;
  font-size: 1.2rem;
  line-height: 50px;
}
</style>
  