<template>
  <div class="hello" style="min-height: 100vh; background-color: #f3f3f3">
    <div class="content">
      <van-icon class="icon" name="success" v-if="status == 'success'" />
      <van-icon class="icon fail" name="cross" v-else />
      <div class="title">
        {{ status == "success" ? "支付成功" : "支付失败" }}
      </div>
      <van-button type="default" size="small" @click="goBack"
        >返回订单</van-button
      >
    </div>
  </div>
</template>
    
  <script>
import { showToast } from "vant";

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      status: "fail",
      oid: false,
    };
  },
  mounted() {
    this.status =
      this.$route.params.status != null && this.$route.params.status
        ? this.$route.params.status
        : "fail";
    this.oid =
      this.$route.query.oid != null && this.$route.query.oid
        ? this.$route.query.oid
        : false;
  },
  methods: {
    goBack() {
      let url = "";
      if (this.oid) {
        url = "/order/detail/" + this.oid;
      } else {
        url = "/orderSearch";
      }
      this.$router.push({ path: url });
    },
  },
};
</script>
<style scoped>
.content {
  text-align: center;
  background: #fff;
  border-radius: 10px;
  padding: 40px;
}
.content .title {
  height: 40px;
  line-height: 40px;
  font-size: 12px;
}
.content .icon {
  font-size: 60px;
}
.green {
  color: #1989fa;
}
.fail {
  color: #ff976a;
}
</style>
    