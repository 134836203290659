<template>
  <div class="hello" style="min-height: 100vh">
    <van-sticky>
      <van-nav-bar
        style="background: #1989fa; color: #ffffff"
        @click-left="$goBacks()"
        @click-right="onClickRight"
      >
        <template #title><span style="color: #ffffff">游轮品牌</span></template>
        <template #left
          ><span style="color: #ffffff">
            <van-icon
              name="arrow-left"
              style="color: #ffffff; margin-right: 6px"
            />返回</span
          ></template
        >
        <template #right
          ><span style="color: #ffffff">
            <van-icon
              name="wap-home-o"
              style="color: #ffffff; font-size: 1.6rem" /></span
        ></template>
      </van-nav-bar>
    </van-sticky>
    <template v-if="hasLoad">
      <van-tabs class="abs" v-model:active="active" @change="onClickTab">
        <van-tab
          :title="type.title"
          :name="type.id"
          v-for="(type, idx) in typeArr"
          :key="idx"
        >
        </van-tab>
      </van-tabs>
      <template v-if="detail">
        <div
          v-if="detail.content != ''"
          class="dibb lcontent van-multi-ellipsis--l5"
          v-html="detail.content"
        ></div>
        <div class="not" v-else>什么也没填写</div>
        <div class="dibb lcontent" v-if="detail.video != ''">
          <video
            ref="myPlayer"
            :src="detail.video"
            t7-video-player-ext="true"
            webkit-playsinline="true"
            x5-playsinline="true"
            controls
            autoplay
            v-if="viewBoatVideo"
            style="width: 100%; height: 240px"
          ></video>
          <div class="video_box" v-else>
            <van-image
              :src="
                detail.thumb + '?x-oss-process=image/resize,m_mfit,w_400,h_400'
              "
              style="height: 100%; width: 100%"
              fit="cover"
              position="center"
            />
            <span class="play">
              <van-icon @click="viewBoatVideo = true" name="play-circle-o" />
            </span>
          </div>
        </div>

        <div class="boat_list">
          <div class="title">
            <van-icon name="guide-o" style="color: #1a89fa; font-size: 18px" />
            旗下游轮
          </div>
          <div class="box">
            <div v-if="detail.boat_list.length">
              <van-row gutter="10">
                <van-col
                  span="12"
                  v-for="(boat, idx) in detail.boat_list"
                  :key="idx"
                >
                  <pro-item type="boat_b" :item="boat"></pro-item>
                </van-col>
              </van-row>
            </div>
            <div class="not" v-else>没有记录</div>
          </div>
        </div>
      </template>
      <van-empty v-else description="未找到记录" />
    </template>
    <in-load v-else />

    <my-footer
      :view="hasLoad"
      :showElse="false"
      :showTabBar="false"
    ></my-footer>
    <share-box v-if="loadShare" :shareData="shareData"></share-box>
  </div>
</template>
  <script>
import { ref } from "vue";
import { showToast } from "vant";
import MyFooter from "@/components/myFooter.vue";
import ProItem from "@/components/ProItem.vue";
import ShareBox from "@/components/ShareBox.vue";
import InLoad from "@/components/InLoad.vue";

export default {
  name: "catalogList",
  components: { MyFooter, ProItem, ShareBox, InLoad },
  data() {
    return {
      hasLoad: false,
      typeArr: [],
      typeId: false,
      loading: false,
      detail: false,
      active: 0,
      viewBoatVideo: false,
      loadShare: false,
      shareData: false,
    };
  },
  setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
  mounted() {
    this.typeId =
      this.$route.params.tags != null && this.$route.params.tags
        ? parseInt(this.$route.params.tags)
        : false;
    if (!this.typeId) {
      showToast("页面不存在");
      setTimeout(() => {
        history.back();
      }, 1500);
      return;
    }

    this.typeArr = JSON.parse(localStorage.getItem("typeArr"));

    if (this.typeId == 9999) {
      this.typeId = this.typeArr[0].id;
    }
    document.getElementById("app").scrollTo(0, 10);
    this.onClickTab(this.typeId);
  },
  methods: {
    onClickRight() {
      this.$router.push("/");
    },
    onClickTab(val) {
      this.typeId = val;
      this.active = val;
      this.getDetail();
    },
    selectType(val) {
      this.typeId = val;
      this.getDetail();
    },
    goUrl(url) {
      if (!url) return;
      if (url == "not") {
        this.$notFound("没有执行航线");
        return;
      }
      this.$router.push(url);
    },
    getDetail() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$http
        .getDetail({
          table: "BoatType",
          hide_success: true,
          typeId: this.typeId,
          fields: "id,title,logo,sort,id boat_list,content,video",
        })
        .then((res) => {
          this.loading = false;
          this.detail = res;
          this.loadShare = true;
          this.hasLoad = true;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.thumb {
  width: 40px;
  height: 40px;
}
.hot {
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}
.hot .con {
  background: #ffffff;
  padding: 20px 10px;
  border-radius: 6px;
}
.hot .stitle {
  padding-bottom: 10px;
}

.content {
  margin: 0 10px;
}
.van-card {
  padding: 10px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}
.not {
  text-align: center;
  color: #666;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
}
.lcontent {
  padding: 16px;
  font-size: 14px;
  line-height: 28px;
  background: #ffffff;
}

.boat_list {
  margin: 16px 0;
  padding: 10px 0;
}
.boat_list .title {
  color: #333;
  background: #ffffff;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  margin-bottom: 10px;
}
.boat_list .box {
  padding: 10px 16px;
}
.video_box {
  width: 100%;
  height: 240px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}
.video_box .play {
  display: block;
  line-height: 240px;
  text-align: center;
  font-size: 5rem;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  overflow: hidden;
}
</style>
    