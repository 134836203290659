<template>
  <div class="hello">
    <van-sticky v-if="detail">
      <van-nav-bar
        style="background: #1989fa; color: #ffffff"
        @click-left="$goBacks()"
        @click-right="onClickRight"
      >
        <template #title
          ><span style="color: #ffffff">{{ pageTitle }}</span></template
        >
        <template #left
          ><span style="color: #ffffff">
            <van-icon
              name="arrow-left"
              style="color: #ffffff; margin-right: 6px"
            />返回</span
          ></template
        >
        <template #right
          ><span style="color: #ffffff">
            <van-icon
              name="wap-home-o"
              style="color: #ffffff; font-size: 1.6rem" /></span
        ></template>
      </van-nav-bar>
    </van-sticky>
    <template v-if="hasLoad && detail">
      <div class="tops" v-if="!detail.tag">
        <div class="title">{{ detail.title }}</div>
        <div class="info">
          <span class="con"><van-icon name="user-o" />三峡游轮</span>
          <span class="con"
            ><van-icon name="clock-o" />时间 {{ detail.times }}</span
          >
        </div>
      </div>
      <div class="content dibb" v-html="detail.content"></div>
    </template>
    <in-load v-else />

    <my-footer
      :view="hasLoad"
      :showElse="false"
      :showTabBar="showTabBar"
    ></my-footer>
    <share-box v-if="loadShare" :shareData="shareData"></share-box>
  </div>
</template>
<script>
import { ref } from "vue";
import { showToast } from "vant";
import MyFooter from "@/components/myFooter.vue";
import ShareBox from "@/components/ShareBox.vue";
import InLoad from "@/components/InLoad.vue";

export default {
  name: "makeOrder",
  components: { MyFooter, ShareBox, InLoad },
  data() {
    return {
      hasLoad: false,
      detail: false,
      tags: false,
      showTabBar: false,
      pageTitle: "资讯详情",
      loadShare: false,
      shareData: false,
    };
  },
  setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
  mounted() {
    this.tags =
      this.$route.params.tags != null && this.$route.params.tags
        ? this.$route.params.tags
        : false;
    if (!this.tags) {
      showToast("页面不存在");
      setTimeout(() => {
        history.back();
      }, 1500);
      return;
    }
    this.getDetail();
  },
  methods: {
    onClickRight() {
      this.$router.push("/");
    },
    resizeImg() {
      setTimeout(() => {
        document.querySelectorAll("img").forEach((item) => {
          item.style.width = "100%";
        });
      }, 100);
    },
    getDetail() {
      this.$http
        .getDetail({
          table: "Notice",
          tag: this.tags,
        })
        .then((res) => {
          this.detail = res;
          this.hasLoad = true;
          if (res.tag) {
            this.pageTitle = res.title;
            this.shareData = {
              title: res.title,
              desc: res.desc ? res.desc : res.stitle ? res.stitle : "没有描述",
              link: false, // 默认获取当前页面 要分享的页面地址不允许存在#参数
              imgUrl:
                process.env.VUE_APP_HOST +
                res.thumb +
                "?x-oss-process=image/resize,m_mfit,w_120,h_120", // 分享出去链接图片
            };
          } else {
            this.pageTitle = "资讯详情";
            this.shareData = {
              title: res.title,
              desc: res.desc ? res.desc : res.stitle ? res.stitle : "没有描述",
              link: false, // 默认获取当前页面 要分享的页面地址不允许存在#参数
              imgUrl:
                process.env.VUE_APP_HOST +
                res.thumb +
                "?x-oss-process=image/resize,m_mfit,w_120,h_120", // 分享出去链接图片
            };
          }
          document.title = res.title;
          this.loadShare = true;
          this.resizeImg();
        })
        .catch((error) => {});
    },
  },
};
</script>
  <style scoped>
.hello {
  min-height: 100vh;
  background: #ffffff;
}
.content {
  margin: 20px;
  font-size: 1.4rem;
  line-height: 2.5rem;
}
.tops {
  text-align: center;
  margin: 20px;
}
.tops .title {
  font-size: 16px;
  line-height: 30px;
}
.tops .info {
  font-size: 1.2rem;
  line-height: 2rem;
  color: #666666;
}
.tops .info .con {
  padding: 6px;
}
</style>
  