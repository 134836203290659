<template>
  <div class="hello" style="min-height: 100vh; background-color: #f3f3f3">
    <template v-if="hasLoad">
      <van-sticky>
        <van-nav-bar
          style="background: #1989fa; color: #ffffff"
          @click-left="onClickLeft"
          @click-right="onClickRight"
        >
          <template #title
            ><span style="color: #ffffff">{{ page_title }}</span></template
          >
          <template #left
            ><span style="color: #ffffff">
              <van-icon
                name="arrow-left"
                style="color: #ffffff; margin-right: 6px"
              />返回</span
            ></template
          >
          <template #right
            ><span style="color: #ffffff">
              <van-icon
                name="wap-home-o"
                style="color: #ffffff; font-size: 1.6rem" /></span
          ></template>
        </van-nav-bar>
        <van-steps :active="step">
          <van-step>选择游轮</van-step>
          <van-step>选择产品</van-step>
          <van-step>游客信息</van-step>
          <van-step>在线支付</van-step>
        </van-steps>
      </van-sticky>
      <template v-if="step == 1">
        <!-- 选择产品 -->
        <div class="top">
          <van-image class="thumb" :src="tmpData.thumb" position="center" />
          <div class="info">
            <div class="item">航线：{{ tmpData.ltitle }}</div>
            <div class="item">游船：{{ tmpData.btitle }}</div>
            <div class="item">房型：{{ tmpData.room.title }}</div>
            <div class="item">日期：{{ tmpData.date }}</div>
          </div>
        </div>
        <my-box style="background-color: #ffffff">
          <template #title>
            <van-icon
              name="user-o"
              style="color: #ff6600; margin-right: 6px; font-size: 18px"
            />出行人数
          </template>
          <template #content>
            <div class="mybox">
              <van-row>
                <van-col span="8"
                  ><div class="item title left">类型</div></van-col
                >
                <van-col span="8"><div class="item title">单价</div></van-col>
                <van-col span="8"><div class="item title">人数</div></van-col>
              </van-row>
              <van-row
                v-for="(item, idx) in tmpData.room.price_list"
                :key="idx"
              >
                <van-col span="8"
                  ><div class="item left">{{ item.title }}</div></van-col
                >
                <van-col span="8">
                  <div class="item price" v-if="item.value">
                    ￥<b>{{ item.value }}</b>
                  </div>
                  <div class="item price" v-else><b>电询</b></div>
                </van-col>
                <van-col span="8"
                  ><div class="item">
                    <van-stepper
                      :disabled="item.disabled"
                      v-model="item.num"
                      min="0"
                      @change="changeNum"
                    /></div
                ></van-col>
              </van-row>
              <div class="tips dibb" v-if="tips" v-html="tips"></div>
            </div>
          </template>
        </my-box>

        <my-box
          v-if="tmpData.baoxian && tmpData.baoxian.length"
          style="background-color: #ffffff"
        >
          <template #title>
            <van-icon
              name="diamond-o"
              style="color: #ff9955; margin-right: 6px; font-size: 18px"
            />保险产品
          </template>
          <template #content>
            <div class="mybox">
              <van-row>
                <van-col span="12"
                  ><div class="item title left">项目</div></van-col
                >
                <van-col span="4"><div class="item title">单价</div></van-col>
                <van-col span="8"><div class="item title">人数</div></van-col>
              </van-row>
              <van-row v-for="(item, idx) in tmpData.baoxian" :key="idx">
                <van-col span="12"
                  ><div class="item left">
                    {{ item.title
                    }}<span v-if="item.desc"><br />{{ item.desc }}</span>
                  </div></van-col
                >
                <van-col span="4"
                  ><div class="item price">
                    ￥<b>{{ item.price }}</b>
                  </div></van-col
                >
                <van-col span="8"
                  ><div class="item">
                    <van-stepper
                      v-model="item.num"
                      min="0"
                      @change="changeNum"
                    /></div
                ></van-col>
              </van-row>
            </div>
          </template>
        </my-box>
        <my-box
          v-if="tmpData.goods && tmpData.goods.length"
          style="background-color: #ffffff"
        >
          <template #title>
            <van-icon
              name="good-job-o"
              style="color: #5566ff; margin-right: 6px; font-size: 18px"
            />其他旅游项目
          </template>
          <template #content>
            <div class="mybox">
              <van-row>
                <van-col span="12"
                  ><div class="item title left">项目</div></van-col
                >
                <van-col span="4"><div class="item title">单价</div></van-col>
                <van-col span="8"><div class="item title">人数</div></van-col>
              </van-row>
              <van-row v-for="(item, idx) in tmpData.goods" :key="idx">
                <van-col span="12"
                  ><div class="item left">
                    {{ item.title
                    }}<span v-if="item.desc"><br />{{ item.desc }}</span>
                  </div></van-col
                >
                <van-col span="4"
                  ><div class="item price">
                    ￥<b>{{ item.price }}</b>
                  </div></van-col
                >
                <van-col span="8"
                  ><div class="item">
                    <van-stepper
                      v-model="item.num"
                      min="0"
                      @change="changeNum"
                    /></div
                ></van-col>
              </van-row>
            </div>
          </template>
        </my-box>
      </template>
      <template v-if="step == 2">
        <!-- 出行人信息 -->
        <div class="top">
          <van-image class="thumb" :src="tmpData.thumb" position="center" />
          <div class="info">
            <div class="item">航线：{{ tmpData.ltitle }}</div>
            <div class="item">游船：{{ tmpData.btitle }}</div>
            <div class="item">房型：{{ tmpData.room.title }}</div>
            <div class="item">日期：{{ tmpData.date }}</div>
          </div>
        </div>
        <div
          style="
            padding: 10px 10px;
            min-height: 100vh;
            background-color: #f3f3f3;
          "
        >
          <van-cell-group inset v-for="(people, i) in people_list" :key="i">
            <template #title>
              <div>
                {{ "游客" + (i + 1) + "信息" }}
                <van-icon
                  @click="deleteUser(i)"
                  style="float: right; font-size: 18px; color: #ff9955"
                  name="delete-o"
                />
              </div>
            </template>
            <van-field
              v-model="people.name"
              required
              label="姓名"
              minlength="2"
              maxlength="50"
              show-word-limit
              :rules="[{ pattern, message: '长度2-10' }]"
              input-align="right"
              placeholder="请输入姓名"
            />

            <van-field
              v-model="people.type_txt"
              is-link
              readonly
              label="证件类型"
              input-align="right"
              placeholder="点击选择"
              @click="showCard(i)"
            />
            <van-popup v-model:show="showPickerCardType" position="bottom">
              <van-picker
                :columns="cardTypeArr"
                @confirm="changeType"
                @cancel="showPickerCardType = false"
              />
            </van-popup>
            <!-- <van-field v-model="people.type" label="证件类型">
              <van-dropdown-menu swipe-threshold="2" style="width: 65vw">
                <van-dropdown-item
                  v-model="people.type"
                  @change="changeType"
                  :options="cardTypeArr"
                >
                  <template #title> {{ tmpData.line_title }} </template>
                </van-dropdown-item>
                <van-dropdown-item
                  v-model="tmpData.type_id"
                  @change="changeType(val, i)"
                  :options="typeArr"
                >
                  <template #title>{{ tmpData.type_title }}</template>
                </van-dropdown-item>
              </van-dropdown-menu>
            </van-field> -->
            <van-field
              v-model="people.idcard"
              label="证件号码"
              input-align="right"
              maxlength="18"
              v-if="people.type == 'idcard'"
              required
              placeholder="请输入证件号码"
            />
            <van-field
              v-model="people.idcard"
              label="证件号码"
              input-align="right"
              maxlength="50"
              v-else
              required
              placeholder="请输入证件号码"
            />
            <van-field
              v-model="people.phone"
              label="手机号"
              input-align="right"
              maxlength="11"
              type="digit"
              placeholder="选填"
            />
          </van-cell-group>

          <div style="text-align: center; padding: 10px">
            <van-button size="small" icon="plus" @click="addUser" type="default"
              >添加游客</van-button
            >
          </div>

          <van-cell-group inset title="联系人信息">
            <van-field
              v-model="contact.name"
              required
              label="称呼"
              maxlength="50"
              placeholder="请输入您的称呼"
            />
            <van-field
              v-model="contact.phone"
              required
              label="手机号"
              maxlength="11"
              type="digit"
              placeholder="请输入手机号"
            />
            <van-field
              v-model="contact.code"
              required
              label="验证码"
              type="digit"
              maxlength="6"
              center
              placeholder="点击获取"
            >
              <template #button>
                <van-button
                  size="small"
                  type="primary"
                  :disabled="disabledBtn"
                  @click="getImgUrl"
                  >{{ btnTxt }}</van-button
                >
              </template>
            </van-field>
            <van-field
              v-model="contact.else"
              label="订单备注"
              type="textarea"
              autosize
              maxlength="100"
              show-word-limit
              placeholder="可在此填写备注信息,最多100字"
            />
          </van-cell-group>
        </div>
      </template>
      <template v-if="step == 3">
        <div class="submit_ok">
          <div class="icon">
            <van-icon name="passed" style="color: #1989fa" />
          </div>
          <van-cell-group>
            <van-cell title="单号" :value="orderData.order_sn" />
            <van-cell title="金额" :value="'￥' + orderData.total" />
            <van-cell title="时间" :value="orderData.create_time" />
            <van-cell title="状态" :value="orderData.status_txt" />
          </van-cell-group>
        </div>
        <van-radio-group v-model="pay_way">
          <van-cell-group inset>
            <van-cell
              v-for="(item, idx) in payArr"
              :key="idx"
              :icon="item.icon"
              :title="item.title"
              clickable
              @click="selectPay(item.tag)"
            >
              <template #right-icon>
                <van-radio :name="item.tag" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <div style="padding: 20px; text-align: center">
          <van-button type="primary" @click="toPay">去支付</van-button>
        </div>
      </template>

      <div class="ftbtn_box" v-if="step == 1">
        <div class="left">
          <span class="price"
            >总计:￥<b>{{ tmpData.total_price }}</b></span
          >
          <span class="price"
            >人数:<b>{{ tmpData.total_people }}</b
            >人</span
          >
        </div>
        <div>
          <van-button style="width: 120px" @click="goNext" block type="primary"
            >下一步</van-button
          >
        </div>
      </div>
      <div class="ftbtn_boxa" v-if="step == 2">
        <van-button style="width: 100px" @click="goBack" type="default"
          >上一步</van-button
        >
        <van-button
          style="width: 100px; margin-left: 10px"
          @click="goNext"
          type="primary"
          :loading="loading"
          loading-type="spinner"
          native-type="submit"
          >提交预订</van-button
        >
      </div>

      <van-popup
        v-model:show="showImgCode"
        round
        position="center"
        :style="{ minHeight: '120px' }"
      >
        <div
          style="
            text-align: center;
            height: 50px;
            line-height: 50px;
            border-bottom: 1px solid #dedede;
          "
        >
          点击图片可刷新
        </div>
        <div style="padding: 10px">
          <van-image
            style="width: 100%"
            @click="getImgUrl"
            :src="imgUrl"
          ></van-image>
        </div>
        <van-field
          v-model="img_code"
          center
          style="border: 1px solid #ff6600; width: 80%; margin: 0 auto"
          required
          :maxlength="4"
          placeholder="在此填入图片验证码"
        >
        </van-field>
        <div style="padding: 30px 20px; text-align: center">
          <van-button type="success" block :loading="loading" @click="sendSms"
            >发送短信验证码</van-button
          >
        </div>
      </van-popup>
    </template>
    <div style="height: 180px"></div>
    <my-footer :view="hasLoad" :showElse="false"></my-footer>
  </div>
</template>
<script>
import { ref } from "vue";
import {
  showNotify,
  showToast,
  BarrageProps,
  BarrageItem,
  BarrageInstance,
} from "vant";
import MyBox from "@/components/MyBox.vue";
export default {
  name: "makeOrder",
  components: {
    MyBox,
  },
  data() {
    return {
      hasLoad: false,
      loading: false,
      tmpData: false,
      disabledBtn: false,
      showImgCode: false,
      cardIdx: 0,
      showPickerCardType: false,
      img_code: "",
      imgUrl: false,
      btnTxt: "发送验证码",
      timer: false,
      page_title: "",
      tips: false,
      code: "",
      step: 1,
      contact: {
        name: "",
        phone: "",
        code: "",
        else: "",
      },
      people_list: [],
      payArr: [
        {
          title: "微信支付",
          tag: "wxpay",
          icon: "/img/wxpay.jpg",
        },
        {
          title: "支付宝",
          tag: "alipay",
          icon: "/img/alipay.jpg",
        },
      ],
      pay_way: "wxpay",
    };
  },
  setup() {
    const onClickLeft = () => history.back();
    const pattern = /\d{6}/;
    return {
      onClickLeft,
      pattern,
    };
  },
  mounted() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    let tmp = localStorage.getItem("tmpData");
    if (!tmp) {
      showToast("参数错误");
      return;
    }
    this.tmpData = JSON.parse(tmp);
    // 初始化数据
    this.tmpData.baoxian = [];
    this.tmpData.goods = [];

    this.tmpData.room.price_list.map((item) => {
      item.num = 0;
      item.value = item.value ? parseInt(item.value) : 0;
      item.disabled = item.value > 0 ? false : true;
    });

    this.tmpData.total_price = 0;
    this.tmpData.total_people = 0;
    this.tmpData.people = 0; //成人数量
    this.page_title = `预订【${this.tmpData.btitle}】`;
    this.people_list = [];
    this.tips = this.tmpData.room.tips;

    this.cardTypeArr = localStorage.getItem("cardTypeArr")
      ? JSON.parse(localStorage.getItem("cardTypeArr"))
      : [];

    this.getDetail();
  },
  methods: {
    showCard(idx) {
      this.cardIdx = idx;
      this.showPickerCardType = true;
    },
    changeType(val) {
      let obj = val.selectedOptions[0];
      this.people_list[this.cardIdx].type = obj.value;
      this.people_list[this.cardIdx].type_txt = obj.text;
      this.showPickerCardType = false;
    },
    getImgUrl() {
      if (!this.checkMobile(this.contact.phone)) {
        showToast("手机号错误");
        return;
      }
      let timeStamp = new Date().getTime();
      this.imgUrl = "/wechat/Index/captcha?ts=" + timeStamp;
      this.showImgCode = true;
    },
    toPay() {
      showToast("支付暂未接入");
      setTimeout(() => {
        _this.$router.push({ path: "/user" });
      }, 1000);
    },
    sendSms() {
      if (this.loading) {
        return;
      }
      if (!this.img_code) {
        showToast("请输入图形验证码");
        return;
      }
      if (!this.checkMobile(this.contact.phone)) {
        showToast("联系人手机号错误");
        return;
      }
      this.loading = true;
      this.$http
        .postData("/Index/sendSms", {
          phone: this.contact.phone,
          scen: "order",
          hide_success: false,
          img_code: this.img_code,
        })
        .then((res) => {
          this.loading = false;
          this.img_code = "";
          this.showImgCode = false;
          this.openTimer();
        })
        .catch((error) => {
          this.getImgUrl();
          this.img_code = "";
          this.loading = false;
        });
    },
    openTimer() {
      let sec = localStorage.getItem("second")
        ? localStorage.getItem("second")
        : 60;

      let _this = this;
      this.timer = setInterval(() => {
        if (sec > 1) {
          sec -= 1;
          _this.disabledBtn = true;
          _this.btnTxt = sec + "秒";
        } else {
          clearInterval(_this.timer);
          _this.disabledBtn = false;
          _this.btnTxt = "发送验证码";
        }
      }, 1000);
    },
    selectPay(val) {
      this.pay_way = val;
    },
    addUser() {
      this.people_list.push({
        name: "",
        phone: "",
        type: "idcard",
        type_txt: "身份证",
        idcard: "",
      });
    },
    deleteUser(idx) {
      if (this.people_list.length == 1) {
        showToast("至少填写一名游客");
        return;
      }
      this.people_list.splice(idx, 1);
    },
    changeNum(val) {
      // 更新价格
      let total_price = 0,
        total_people = 0,
        boat_price = 0,
        baoxian_price = 0,
        goods_price = 0;

      this.tmpData.room.price_list.map((item, idx) => {
        if (item.smp == 1) {
          // 记录成人数量
          this.tmpData.people += item.num;
          total_people += item.num;
        }
        total_price += item.num * parseFloat(item.value);
        boat_price += item.num * parseFloat(item.value);
      });
      this.tmpData.baoxian.map((item) => {
        total_price += item.num * parseFloat(item.price);
        baoxian_price += item.num * parseFloat(item.price);
      });
      this.tmpData.goods.map((item) => {
        total_price += item.num * parseFloat(item.price);
        goods_price += item.num * parseFloat(item.price);
      });

      this.tmpData.total_price = total_price;
      this.tmpData.boat_price = boat_price;
      this.tmpData.goods_price = goods_price;
      this.tmpData.baoxian_price = baoxian_price;
      this.tmpData.total_people = total_people;

      let n = this.tmpData.room.price_list[0].num;
      let dif = n - this.people_list.length;
      // 默认只添加一名游客
      if (this.people_list.length < 1) {
        this.people_list.push({
          name: "",
          phone: "",
          type: "idcard",
          type_txt: "身份证",
          idcard: "",
        });
      }
    },
    onClickRight() {
      this.$router.push("/");
    },
    goNext() {
      let next = this.step + 1;
      if (next == 2) {
        if (this.tmpData.people < 1) {
          showToast("出行人数至少1名成人");
          return;
        }
        // 保存订单数据
        localStorage.setItem("orderData", JSON.stringify(this.tmpData));
        this.step = next;
      }
      if (next == 3) {
        let error = false;
        this.people_list.some((item, idx) => {
          // 第一名游客必填
          if (!item.name) {
            error = `游客${idx + 1}姓名必填`;
            return true;
          }
          if (item.type == "idcard") {
            if (!this.checkIdCard(item.idcard)) {
              error = `游客${idx + 1}证件号码有误`;
              return true;
            }
          } else {
            if (item.idcard == "") {
              error = `游客${idx + 1}证件号码有误`;
              return true;
            }
          }
          if (item.phone != "" && !this.checkMobile(item.phone)) {
            error = `游客${idx + 1}手机号有误`;
            return true;
          }
        });
        if (error) {
          showToast(error);
          return;
        }
        if (!this.contact.name || !this.contact.phone || !this.contact.code) {
          showToast("请完正确填写联系人信息");
          return;
        }
        this.toSubmit();
      }
    },
    goBack() {
      let next = this.step - 1;
      if (next < 1) {
        return;
      }
      this.step = next;
    },
    getDetail() {
      this.loading = true;
      this.$http
        .postData("/Index/getTdata", {})
        .then((res) => {
          this.loading = false;
          this.hasLoad = true;
          res.baoxian.map((item) => {
            item.num = 0;
            this.tmpData.baoxian.push(item);
          });
          res.product.map((item) => {
            item.num = 0;
            this.tmpData.goods.push(item);
          });
          // 统一的设置
          // this.tips = localStorage.getItem('order_tips')
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    toSubmit() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      if (!this.checkMobile(this.contact.phone)) {
        showToast("联系人手机号错误");
        return;
      }
      if (!/^\d{6}$/.test(this.contact.code)) {
        showToast("短信验证码格式错误");
        return;
      }
      this.$http
        .postData("/Order/apply", {
          order: this.tmpData,
          people_list: this.people_list,
          contact: this.contact,
          hide_success: false,
          scen: "order",
        })
        .then((res) => {
          this.loading = false;
          if (res.user) {
            localStorage.setItem("userInfo", JSON.stringify(res.user));
          }
          delete res.user;
          this.orderData = res;
          this.$router.push({ path: "/order/detail/" + res.id });
          // this.step = 3;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    checkIdCard(id_number) {
      id_number = id_number.toUpperCase(); // 身份证为末尾可能是X

      // 身份证号码验证
      // if( /(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(id_number) ){
      //   // 身份证
      //   return true;
      // }
      let isId = this.validataCredentials(id_number);

      if (isId) {
        return true;
      }

      if (/^[A-Z]\d{7}$/.test(id_number)) {
        // 护照
        return true;
      }
      if (/^[CHKM]\d{8}$/.test(id_number)) {
        // 港澳通行证
        return true;
      }

      return false;
    },
    checkMobile(phone) {
      if (/^1\d{10}$/.test(phone)) {
        return true;
      }
      return false;
    },
    validataCredentials(num) {
      var num = num.toUpperCase(); // 身份证为末尾可能是X
      //   身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，
      //   最后一位是校验位，可能为数字或字符X。
      const arrInt = new Array(
        7,
        9,
        10,
        5,
        8,
        4,
        2,
        1,
        6,
        3,
        7,
        9,
        10,
        5,
        8,
        4,
        2
      );
      const arrCh = new Array(
        "1",
        "0",
        "X",
        "9",
        "8",
        "7",
        "6",
        "5",
        "4",
        "3",
        "2"
      );
      var nTemp = 0,
        i;
      if (!/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num)) {
        //  输入的身份证号长度不对，或者号码不符合规定;
        return false;
      }
      //   校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
      //   下面分别分析出生日期和校验位
      var len, re;
      len = num.length;
      if (len == 15) {
        re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/);
        var arrSplit = num.match(re);
        //   检查生日日期是否正确
        var dtmBirth = new Date(
          "19" + arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]
        );
        var bGoodDay;
        bGoodDay =
          dtmBirth.getYear() == Number(arrSplit[2]) &&
          dtmBirth.getMonth() + 1 == Number(arrSplit[3]) &&
          dtmBirth.getDate() == Number(arrSplit[4]);
        if (!bGoodDay) {
          //   输入的身份证号里出生日期不对！
          return false;
        } else {
          //   将15位身份证转成18位
          //   校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
          num = num.substr(0, 6) + "19" + num.substr(6, num.length - 6);
          for (i = 0; i < 17; i++) {
            nTemp += num.substr(i, 1) * arrInt[i];
          }
          num += arrCh[nTemp % 11];
          return true;
        }
      }
      if (len == 18) {
        re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/);
        var arrSplit = num.match(re);
        //  检查生日日期是否正确
        var dtmBirth = new Date(
          arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]
        );
        var bGoodDay;
        bGoodDay =
          dtmBirth.getFullYear() == Number(arrSplit[2]) &&
          dtmBirth.getMonth() + 1 == Number(arrSplit[3]) &&
          dtmBirth.getDate() == Number(arrSplit[4]);
        if (!bGoodDay) {
          //  输入的身份证号里出生日期不对！
          return false;
        } else {
          var valnum;
          for (i = 0; i < 17; i++) {
            nTemp += num.substr(i, 1) * arrInt[i];
          }
          valnum = arrCh[nTemp % 11];
          if (valnum != num.substr(17, 1)) {
            //  18位身份证的校验码不正确！
            return false;
          }
          return true;
        }
      }
      return false;
    },
  },
};
</script>
  <style scoped>
.footer_bar {
  display: none;
}
.page_title {
  height: 40px;
  line-height: 40px;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
  background: rgba(49, 151, 253, 1);
  color: #ffffff;
  text-align: center;
}
.top {
  line-height: 28px;
  padding: 16px;
  min-height: 120px;
  margin-top: -20px;
  background: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  position: relative;
}
.top .thumb {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  left: 16px;
  top: 16px;
}
.top .item {
  padding-left: 116px;
  font-size: 1.2rem;
  line-height: 2.3rem;
}
.mybox {
}
.mybox .tips {
  font-size: 12px;
  color: #666;
  padding: 20px 10px;
}
.mybox .item {
  text-align: center;
  padding: 5px 0;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
}
.mybox .title {
  color: #000000;
  line-height: 30px;
  font-size: 14px;
}
.mybox .price {
  font-size: 10px;
}
.mybox .price b {
  color: #ff5a00;
  font-size: 18px;
  font-weight: 300;
}

.mybox .left {
  text-align: left !important;
  padding-left: 10px !important;
}

.mybox .item span {
  font-size: 0.6rem;
  color: #999999;
}
.ftbtn_boxa {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background: #fff;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 8px;
}
.ftbtn_box {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  display: flex;
  justify-content: space-around;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 8px;
}
.ftbtn_box .left {
  line-height: 44px;
  font-size: 14px;
}
.ftbtn_box .price {
  padding: 0 10px;
}
.ftbtn_box .price b {
  font-size: 20px;
  color: #ff5a00;
  font-weight: 300;
}
.submit_ok {
  margin: 20px;
  background: #ffffff;
  border-radius: 10px;
}
.submit_ok .icon {
  text-align: center;
  font-size: 80px;
}
</style>
  