import { createRouter, createWebHashHistory, createWebHistory, useRouter } from "vue-router"
import { showToast } from 'vant';
import Home from "../views/home"
import User from "../views/user/index"
import Login from "../views/user/login"
import Detail from "../views/detail/index"
import special from "../views/special"
import makeOrder from "../views/submit/order"
import baochuan from "../views/submit/baochuan"
import search from "../views/search"
// import shipMentDay from "../views/shipMent/day"
import godate from "../views/shipMent/day"

import single from "../views/article/single"
import catalog from "../views/article/list"
import boatType from "../views/boatType"
import orderDetail from "../views/user/orderDetail"
import orderPay from "../views/user/orderPay"
import loginFromWechat from "../views/user/loginFromWechat"
import payStatus from "../views/user/payStatus"
import orderSearch from "../views/submit/orderSearch"
import complain from "../views/complain"
import wx from 'weixin-js-sdk';	// 使用js-sdk

const routes = [{
    name: 'home',
    path: '/',
    component: Home,
    meta: {
        title: '首页',
        auth: false
    }
}, {
    name: 'search',
    path: '/search',
    component: search,
    meta: {
        title: '搜索航线',
        auth: false
    }
}, {
    name: 'makeOrder',
    path: '/makeOrder',
    component: makeOrder,
    meta: {
        title: '在线预订',
        auth: false
    }
}, {
    name: 'catalog',
    path: '/catalog/:tags',
    component: catalog,
    meta: {
        title: '资讯',
        auth: false
    }
},{
    name: 'pay',
    path: '/pay/:status',
    component: payStatus,
    meta: {
        title: '支付结果',
        auth: false
    }
}, {
    name: 'page',
    path: '/page/:tags',
    component: single,
    meta: {
        title: '单页详情',
        auth: false
    }
}, {
    name: 'articleDetail',
    path: '/article/detail/:tags',
    component: single,
    meta: {
        title: '资讯详情',
        auth: false
    }
    },
    // {
    // name: 'shipMent',
    // path: '/shipMent/day',
    // component: shipMentDay,
    // meta: {
    //     title: '航线查询',
    //     auth: false
    // }
    // },
    {
    name: 'shipMent',
    path: '/shipMent/godate',
    component: godate,
    meta: {
        title: '船期表',
        auth: false
    }
}, {
    name: 'special',
    path: '/special/:tag',
    component: special,
    meta: {
        title: '专题',
        auth: false
    }
},  {
    name: 'orderSearch',
    path: '/orderSearch',
    component: orderSearch,
    meta: {
        title: '订单查询',
        auth: false
    }
},{
    name: 'user',
    path: '/user',
    component: User,
    meta: {
        title: '我的',
        auth: true
    }
}, {
    name: 'order',
    path: '/order/detail/:oid',
    component: orderDetail,
    meta: {
        title: '查看订单',
        auth: false
    }
},{
    name: 'complain',
    path: '/complain',
    component: complain,
    meta: {
        title: '投诉建议',
        auth: false
    }
    },
    // {
    // name: 'orderPay',
    // path: '/order/pay/:oid',
    // component: orderPay,
    // meta: {
    //     title: '在线支付',
    //     auth: false
    // }
    // },
    {
    name: 'login',
    path: '/user/login',
    component: Login,
    meta: {
        title: '登陆',
        auth: false
    }
},{
    name: 'loginFromWechat',
    path: '/user/loginFromWechat',
    component: loginFromWechat,
    meta: {
        title: '登陆',
        auth: false
    }
}, {
    name: 'boatType',
    path: '/boat/type/:tags',
    component: boatType,
    meta: {
        title: '游轮品牌',
        auth: false
    }
}, {
    name: 'detail',
    path: '/:type/detail/:sid',
    component: Detail,
    meta: {
        title: '详情',
        auth: false
    }
}]

const router = createRouter({
    // history: createWebHashHistory(),
    history:createWebHistory(),
    routes
})

const checkDevice = function () {
    var ua = navigator.userAgent.toLowerCase(); //判断是否是微信浏览器

    if (navigator.userAgent.includes('miniProgram')) {
        localStorage.setItem("device", "mini");
        return
    }

    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //微信环境下
        wx.miniProgram.getEnv(function (res) {
          if (res.miniprogram) {
              // 小程序环境下逻辑
              localStorage.setItem("device", "mini");
          } else {
              //非小程序环境下逻辑
              localStorage.setItem("device", "wechat");
          }
        });
    } else {
        localStorage.setItem("device", "else");
    }
}

router.beforeEach((to, from, next) => {
    let userInfo = localStorage.getItem('userInfo')
    checkDevice()
    userInfo = userInfo ? JSON.parse(userInfo) : false
    let token = userInfo ? userInfo.token : false
    if (to.meta.auth) {
        if (!token) {
            localStorage.setItem('redirect_url', to.path)
            localStorage.removeItem('userInfo')
            next({
                path: '/user/login'
            })
        } else {
            next()
        }
    } else {
        if (to.name == 'login' && token) {
            next({
                path: '/'
            })
        } else {
            next()
        }
    }
})
router.afterEach(to => {
    // 更改标题
    let webTitle = localStorage.getItem("webTitle");
    const titleText = to.meta.title
    const processTitle = webTitle || '请稍后'
    window.document.title = `${processTitle}${titleText ? ` | ${titleText}` : ''}`
})

export default router

