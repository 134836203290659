<template>
  <div class="hello">
    <div class="bg"></div>
    <div class="content" v-if="step == 1">
      <van-cell-group inset>
        <template #title>
          <div
            style="
              text-align: center;
              line-height: 30px;
              font-size: 1.6rem;
              color: #333;
            "
          >
            订单查询
            <div
              style="
                font-size: 1.2rem;
                text-align: center;
                padding: 0px 40px;
                margin-top: 10px;
              "
            >
              <van-radio-group
                v-model="searchData.type"
                style="justify-content: space-around"
                direction="horizontal"
                @click="clickRd"
              >
                <van-radio name="phone">手机号</van-radio>
                <van-radio name="order_sn">订单号</van-radio>
              </van-radio-group>
            </div>
          </div>
        </template>

        <van-field
          v-model="searchData.keys"
          clearable
          required
          type="digit"
          :maxlength="20"
          label="订单号"
          input-align="right"
          v-if="searchData.type == 'order_sn'"
          placeholder="请填写订单号"
        />
        <van-field
          v-model="searchData.keys"
          required
          type="digit"
          :maxlength="11"
          v-if="searchData.type == 'phone'"
          label="手机号"
          input-align="right"
          placeholder="联系人手机号"
        />
        <van-field
          v-model="searchData.img_code"
          center
          required
          :maxlength="4"
          input-align="right"
          label="验证码"
          placeholder="图片验证码,点击可刷新"
        >
        </van-field>
        <van-cell>
          <template #value>
            <van-image
              style="width: 100%; height: 60px"
              @click="getImgUrl"
              :src="imgUrl"
            ></van-image>
          </template>
        </van-cell>

        <div style="margin: 16px">
          <van-button
            round
            block
            :loading="loading"
            loading-type="spinner"
            type="primary"
            @click="searchDo"
          >
            立即查询
          </van-button>
        </div>
      </van-cell-group>
    </div>
    <div class="order_box" v-if="step == 2">
      <van-nav-bar
        style="background: #1989fa; color: #ffffff"
        @click-left="goPrev"
      >
        <template #title>
          <span style="color: #ffffff">查询结果</span>
        </template>
        <template #left
          ><span style="color: #ffffff">
            <van-icon
              name="arrow-left"
              style="color: #ffffff; margin-right: 6px"
            />返回</span
          ></template
        >
      </van-nav-bar>
      <div
        class="item"
        v-for="item in dataList"
        @click="viewOrder(item.id)"
        :key="item.id"
      >
        <div class="title">
          单号 {{ item.order_sn }}
          <span class="tags">
            <van-tag type="danger" v-if="item.status == 1">{{
              item.status_txt
            }}</van-tag>
            <van-tag type="warning" v-else-if="item.status == 2">{{
              item.status_txt
            }}</van-tag>
            <van-tag type="success" v-else-if="item.status == 3">{{
              item.status_txt
            }}</van-tag>
            <van-tag type="warning" v-else-if="item.status == 4">{{
              item.status_txt
            }}</van-tag>
            <van-tag type="default" v-else>{{ item.status_txt }}</van-tag>
          </span>
        </div>
        <div class="con">
          <div>
            <van-image class="thumb" fit="cover" :src="item.room.thumb">
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </div>
          <div class="right">
            <van-text-ellipsis :content="item.title" />
            <div>
              订单金额:
              <span class="price"
                >￥<b>{{ item.total }}</b></span
              >
            </div>
            <div>出行日期:{{ item.godate }}</div>
          </div>
        </div>
        <div class="footer">
          {{ item.create_time }}
          <van-button
            size="mini"
            v-if="item.status == 1"
            style="float: right"
            plain
            hairline
            type="danger"
            >去支付</van-button
          >
        </div>
      </div>
      <div class="notfind" v-if="!dataList.length">没有查询到订单</div>
    </div>
    <my-footer
      :view="true"
      :showElse="false"
      :showTabBar="true"
      activeStr="searchOrder"
    ></my-footer>
    <share-box v-if="loadShare" :shareData="shareData"></share-box>
  </div>
</template>
  
<script>
import { showToast } from "vant";
import MyFooter from "@/components/myFooter.vue";
import ShareBox from "@/components/ShareBox.vue";
import InLoad from "../../components/InLoad.vue";

export default {
  name: "Login",
  components: {
    MyFooter,
    ShareBox,
    InLoad,
  },
  data() {
    return {
      loadShare: false,
      loading: false,
      showImgCode: false,
      disabledBtn: false,
      step: 1,
      dataList: [],
      btnTxt: "发送验证码",
      searchData: {
        keys: "",
        img_code: "",
        type: "phone",
      },
      imgUrl: false,
      shareData: {
        title: "三峡游轮售票中心-订单查询",
        desc: "根据订单号或预订手机号查询",
        imgUrl:
          process.env.VUE_APP_HOST +
          "/oss/2024-09-07/3aed55e212317f6661b22d196be03139.jpg?x-oss-process=image/resize,m_mfit,w_120,h_120",
        link: false,
      },
    };
  },
  mounted() {
    this.getImgUrl();
    this.loadShare = true;
  },
  methods: {
    clickRd() {
      this.searchData.keys = "";
    },
    getImgUrl() {
      let timeStamp = new Date().getTime();
      this.imgUrl = "/wechat/Index/captcha?ts=" + timeStamp;
    },
    goNext() {
      this.step = 2;
    },
    goPrev() {
      this.step = 1;
      this.searchData = {
        keys: "",
        img_code: "",
        type: "order_sn",
      };
    },
    viewOrder(oid) {
      this.$router.push({ path: "/order/detail/" + oid });
    },
    checkMobile(phone) {
      if (/^1\d{10}$/.test(phone)) {
        return true;
      }
      return false;
    },
    searchDo() {
      if (this.searchData.type == "order_sn" && !this.searchData.keys) {
        showToast("请填写订单号");
        return;
      }
      if (
        this.searchData.type == "phone" &&
        !this.checkMobile(this.searchData.keys)
      ) {
        showToast("请填写正确的手机号");
        return;
      }
      // if (!/^\w{5}$/.test(this.searchData.img_code)) {
      //   showToast("请正确填写验证码");
      //   return;
      // }
      if (!this.searchData.img_code) {
        showToast("请正确填写验证码");
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$http
        .searchOrder({ ...this.searchData })
        .then((res) => {
          this.loading = false;
          this.dataList = res;
          this.goNext();
        })
        .catch((e) => {
          this.loading = false;
          this.getImgUrl();
        });
    },
  },
};
</script>
<style scoped>
.hello {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  background-color: #ffffff;
  position: relative;
}
.bg {
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url("/public/img/login_bg.jpg");
  background-position: top center;
  background-size: 100% 100%;
  min-height: 100vh;
  opacity: 0.4;
}
.content {
  position: absolute;
  z-index: 2;
  top: 40px;
  left: 10px;
  right: 10px;
}

.order_box {
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  right: 0px;
}

.order_box .item {
  margin: 10px;
  background: #fff;
  padding: 10px;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
.order_box .item .title {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 6px;
  position: relative;
}
.order_box .item .title .tags {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: -6px;
}
.order_box .item .con {
  font-size: 12px;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}
.order_box .item .right {
  padding-left: 20px;
  flex: 1;
  line-height: 24px;
  color: #666;
}

.order_box .item .con .thumb {
  width: 60px;
  height: 60px;
  border-radius: 6px;
}
.order_box .item .footer {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #999;
  border-top: 1px solid #f3f3f3;
  padding-top: 6px;
}
.notfind {
  height: 150px;
  line-height: 150px;
  color: #666;
  font-size: 1.2rem;
  text-align: center;
}
</style>
  