<template>
  <span class="info">
    <template v-if="price != '' || price > 0">
      <span class="before">{{ before }}</span>
      <span
        class="price"
        :class="{
          def: size == 'def',
          large: size == 'large',
          small: size == 'small',
        }"
        >{{ price }}</span
      >
      <span class="after">{{ after }}</span>
    </template>
    <template v-else>
      <span class="price def">电询</span>
    </template>
  </span>
</template>
  
  <script>
export default {
  name: "myPrice",
  props: {
    size: {
      type: String,
      default: "def",
    },
    price: {
      type: Number,
      default: 0,
    },
    before: {
      type: String,
      default: "￥",
    },
    after: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
  
  <style scoped>
.info {
  font-size: 1rem;
}
.info .before {
  color: #ff8800;
}
.info .after {
  color: #999999;
}
.info .price {
  color: #ff8800;
  padding: 0 2px;
}
.large {
  font-size: 1.8rem !important;
}
.small {
  font-size: 1.2rem !important;
}
.def {
  font-size: 1.5rem !important;
}
</style>
  