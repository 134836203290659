<template>
  <div class="hello">
    <div class="bg"></div>
    <div class="content">
      <van-nav-bar
        style="background: #1989fa; color: #ffffff"
        @click-left="$goBacks()"
      >
        <template #title>
          <span style="color: #ffffff">投诉建议</span>
        </template>
        <template #left
          ><span style="color: #ffffff">
            <van-icon
              name="arrow-left"
              style="color: #ffffff; margin-right: 6px"
            />返回</span
          ></template
        >
      </van-nav-bar>
      <van-cell-group inset style="margin-top: 20px">
        <van-field
          v-model="saveData.username"
          clearable
          required
          type="input"
          :maxlength="10"
          label="您的称呼"
          label-align="right"
          placeholder="请填写您的称呼"
        />
        <van-field
          v-model="saveData.phone"
          clearable
          required
          type="digit"
          :maxlength="11"
          label="联系电话"
          label-align="right"
          placeholder="请填写联系电话"
        />
        <van-field
          v-model="saveData.content"
          rows="2"
          autosize
          type="textarea"
          required
          show-word-limit
          :maxlength="300"
          label="留言内容"
          label-align="right"
          placeholder="请填写投诉或建议内容,500字内"
        />

        <van-field
          v-model="saveData.img_code"
          center
          clearable
          required
          :maxlength="4"
          label-align="right"
          label="验证码"
          placeholder="请填写计算结果,点击可刷新"
        >
        </van-field>
        <van-cell>
          <template #value>
            <van-image
              style="width: 100%; height: 60px"
              @click="getImgUrl"
              :src="imgUrl"
            ></van-image>
          </template>
        </van-cell>

        <div style="margin: 16px">
          <van-button
            round
            block
            :loading="loading"
            :disabled="loading"
            loading-type="spinner"
            type="primary"
            @click="searchDo"
          >
            提 交
          </van-button>
        </div>
      </van-cell-group>
    </div>

    <my-footer :view="false" :showElse="false" :showTabBar="false"></my-footer>
  </div>
</template>
  
<script>
import { showToast } from "vant";
import MyFooter from "@/components/myFooter.vue";

export default {
  name: "Login",
  components: {
    MyFooter,
  },
  data() {
    return {
      loading: false,
      showImgCode: false,
      saveData: {
        content: "",
        img_code: "",
        username: "",
        phone: "",
      },
      imgUrl: false,
    };
  },
  mounted() {
    this.getImgUrl();
  },
  methods: {
    getImgUrl() {
      let timeStamp = new Date().getTime();
      this.imgUrl = "/wechat/Index/captcha?ts=" + timeStamp;
    },
    goPrev() {
      this.$router.go(-1);
    },
    checkMobile(phone) {
      if (/^1\d{10}$/.test(phone)) {
        return true;
      }
      return false;
    },
    searchDo() {
      if (!this.saveData.username) {
        showToast("请填写您的称呼");
        return;
      }
      if (!this.checkMobile(this.saveData.phone)) {
        showToast("请填写正确的手机号");
        return;
      }
      if (!this.saveData.content) {
        showToast("请填写留言内容");
        return;
      }
      //   if (!/^\w{5}$/.test(this.saveData.img_code)) {
      //     showToast("请正确填写验证码");
      //     return;
      //   }
      if (!this.saveData.img_code) {
        showToast("请正确填写验证码");
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$http
        .postData("/applyComplain", {
          ...this.saveData,
          hide_success: false,
        })
        .then((res) => {
          this.loading = false;
          let _this = this;
          setTimeout(() => {
            _this.goPrev();
          }, 1000);
        })
        .catch((e) => {
          this.loading = false;
          this.getImgUrl();
        });
    },
  },
};
</script>
<style scoped>
.hello {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  background-color: #ffffff;
  position: relative;
}
.bg {
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url("/public/img/login_bg.jpg");
  background-position: top center;
  background-size: 100% 100%;
  min-height: 100vh;
  opacity: 0.4;
}
.content {
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  right: 0px;
}
</style>
  