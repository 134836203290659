<template>
    <div>
        <van-popup v-model:show="view" round position="bottom" :style="{height:'500px'}">
            <div class="view_room" ref="view_room" v-if="view && roomData">
                    <div class="title">{{ roomData.title }}</div>
                <div class="imgs">
                    <van-swipe :autoplay="1000000" lazy-render>
                        <van-swipe-item v-if="roomData.video">
                            <video ref="myPlayer" :src="roomData.video" autoplay t7-video-player-ext="true" webkit-playsinline="true" x5-playsinline="true" style="width: 100%;height: 200px;"></video>
                        </van-swipe-item>
                        <van-swipe-item v-for="(item,i) in roomData.imgs" :key="i">
                            <van-image fit="cover" :src="item.url" style="width:100%;height: 200px;"></van-image>
                        </van-swipe-item>
                        <van-swipe-item v-for="(item,i) in roomData.imgs" :key="i">
                            <van-image fit="cover" :src="item.url" style="width:100%;height: 200px;"></van-image>
                        </van-swipe-item>
                        <van-swipe-item v-for="(item,i) in roomData.imgs" :key="i">
                            <van-image fit="cover" :src="item.url" style="width:100%;height: 200px;"></van-image>
                        </van-swipe-item>
                    </van-swipe>
                </div>
                <div class="cansu">
                    <div class="item" v-for="(item,idx) in roomData.content" :key="idx">{{ item.title }} : {{ item.value }}</div>
                </div>
                <div style="margin:20px;text-align: center;"><van-button @click="$notFound" type="primary" block>立即预订</van-button></div>
            </div>
        </van-popup>
    </div>
</template>
  
  <script>
  export default {
    props: {
        show: {
          type: Boolean,
          default: true
        },
        roomData: {
          type: Object,
          default: ()=>{
            return {}
          }
        }
    },
    data(){
      return {
        view:false
      }
    },
    mounted() {
        this.view = this.show
    },
    methods: {
    },
  }
  </script>
  
  <style scoped>
  .view_room{
}
.view_room .title{
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 1.2rem;
    box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.3);
}
.view_room .imgs{
    margin:10px;
}
.view_room .imgs .thumb{
    width: 100%;
}
.view_room .cansu{
    margin:0 20px;
    line-height: 28px;
    font-size: 14px;
}
  </style>
  