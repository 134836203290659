<template>
  <div class="hello" style="min-height: 100vh; background-color: #f3f3f3">
    <template v-if="hasLoad">
      <van-sticky style="z-index: 100">
        <van-nav-bar
          style="background: #1989fa; color: #ffffff"
          @click-left="$goBacks()"
          @click-right="onClickRight"
        >
          <template #title>
            <span style="color: #ffffff">{{ page_title }}</span>
          </template>
          <template #left
            ><span style="color: #ffffff">
              <van-icon
                name="arrow-left"
                style="color: #ffffff; margin-right: 6px"
              />返回</span
            ></template
          >
          <template #right
            ><span style="color: #ffffff">
              <van-icon
                name="wap-home-o"
                style="color: #ffffff; font-size: 1.6rem" /></span
          ></template>
        </van-nav-bar>
      </van-sticky>
      <van-sticky>
        <div class="filter">
          <van-search
            v-model="tmpData.keyword"
            shape="round"
            placeholder="请输入搜索关键词"
            @search="onSearch"
          >
          </van-search>
        </div>
      </van-sticky>
      <div v-if="hasLoad && dataList.length">
        <div
          class="hot_line"
          @click="goUrl(item.url_line)"
          v-for="(item, idx) in dataList"
          :key="idx"
        >
          <van-image
            fit="cover"
            class="thumb"
            position="center"
            :src="item.thumb + '?x-oss-process=image/resize,m_mfit,w_200,h_140'"
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
          <div class="btitle">{{ item.btitle }}</div>
          <div class="content">
            <div class="title">
              <van-text-ellipsis rows="2" :content="item.title" />
            </div>
            <div class="cansu" v-if="item.cansu.length">
              <template v-for="(cs, idx) in item.cansu">
                <span :key="idx" v-if="idx < 4"
                  >{{ cs.title }}:{{ cs.value ? cs.value : "-" }}</span
                >
              </template>
            </div>
            <div
              v-if="item.tags && item.tags.length"
              style="height: 20px; line-height: 20px; overflow: hidden"
            >
              <van-tag
                v-for="(t, i) in item.tags"
                :key="i"
                type="success"
                plain
                style="margin-right: 4px"
                >{{ t }}</van-tag
              >
            </div>
            <div class="jindian">
              <van-text-ellipsis rows="1" :content="item.jd" />
            </div>
            <div class="price_info">
              <my-price
                :price="item.mins"
                size="large"
                before="￥"
                after="起"
              />
            </div>
            <div class="btn">立即预订</div>
          </div>
        </div>
      </div>
      <in-load v-else text="没有数据，建议更换关键词查询" />
    </template>
    <in-load v-else title="数据获取中" />
    <my-footer
      :view="hasLoad"
      :showElse="false"
      :showTabBar="false"
    ></my-footer>
    <share-box v-if="loadShare" :shareData="shareData"></share-box>
  </div>
</template>
<script>
import { ref } from "vue";
import {
  showNotify,
  showToast,
  BarrageProps,
  BarrageItem,
  BarrageInstance,
} from "vant";
import MyBox from "@/components/MyBox.vue";
import MyFooter from "@/components/myFooter.vue";
import myPrice from "@/components/myPrice.vue";
import ShareBox from "@/components/ShareBox.vue";
import InLoad from "@/components/InLoad.vue";

export default {
  name: "search",
  components: {
    MyBox,
    MyFooter,
    myPrice,
    ShareBox,
    InLoad,
  },
  data() {
    return {
      hasLoad: false,
      loading: false,
      tmpData: {
        keyword: "",
      },
      page_title: "搜索",
      matouArr: [], //码头数组
      dataList: [],
      showdate: false,
      today: {
        date: "",
        week: "",
      },
      loadShare: true,
      shareData: {
        title: "三峡游轮售票中心-游船查询",
        desc: "查询游船",
        imgUrl:
          process.env.VUE_APP_HOST +
          "/oss/2024-09-07/3aed55e212317f6661b22d196be03139.jpg?x-oss-process=image/resize,m_mfit,w_120,h_120",
        link: false,
      },
    };
  },
  setup() {},
  mounted() {
    this.tmpData.keyword =
      this.$route.query.kwd != null && this.$route.query.kwd
        ? this.$route.query.kwd
        : "";
    // let tmp = localStorage.getItem("searchData");
    // let matou = localStorage.getItem("matouArr");
    // this.matouArr = matou ? JSON.parse(matou) : [];
    // if (!tmp) {
    //   showToast("参数错误");
    //   return;
    // }
    // this.tmpData = JSON.parse(tmp);
    // // 加减数量
    // this.tmpData.ns = 0;
    // 初始化数据
    this.page_title = `搜索中`;
    this.onSearch();
  },
  methods: {
    onClickRight() {
      this.$router.push("/");
    },
    onClickLeft() {
      this.$goBacks();
    },
    onSearch() {
      if (!this.tmpData.keyword) {
        showToast("请输入关键词");
        return;
      }
      this.getList();
    },
    nextDay() {
      this.tmpData.ns += 1;
      this.getList();
    },
    showDateBox() {
      this.showdate = true;
    },
    onConfirm(date) {
      let m = date.getMonth() + 1;
      let d = date.getDate();
      this.tmpData.ns = 0;
      this.tmpData.date = `${date.getFullYear()}-${m < 10 ? "0" + m : m}-${
        d < 10 ? "0" + d : d
      }`;
      localStorage.setItem("searchData", JSON.stringify(this.tmpData));
      this.showdate = false;
      this.getList();
    },
    changeMatou() {
      // let a = this.tmpData.up;
      // let b = this.tmpData.down;
      // this.tmpData.up = b;
      // this.tmpData.down = a;
      // localStorage.setItem("searchData", JSON.stringify(this.tmpData));
      // this.page_title = `${this.tmpData.up.title}<->${this.tmpData.down.title}`;
      this.getList();
    },
    getList() {
      this.loading = true;
      let form = {
        st: "keyword", // month day boat line 查询类型
        keyword: this.tmpData.keyword,
      };
      this.$http
        .postData("/Index/getShipMent", { ...form })
        .then((res) => {
          this.loading = false;
          this.hasLoad = true;
          this.dataList = res.list;
          this.today = res.today;
          this.page_title = `找到${res.list.length}条记录`;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    goUrl(url) {
      if (!url) return;
      if (url == "not") {
        this.$notFound("没有执行航线");
        return;
      }
      this.$router.push(url);
    },
  },
};
</script>
  <style scoped>
.filter {
  background: #f3f3f3;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.2);
}
.hot_line {
  background: #ffffff;
  border-radius: 4px;
  margin: 10px;
  font-size: 12px;
  min-height: 154px;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.3);
  position: relative;
}
.hot_line .thumb {
  width: 130px;
  height: 130px;
  border-radius: 6px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 10px;
}
.hot_line .btitle {
  width: 130px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  position: absolute;
  z-index: 2;
  left: 10px;
  bottom: 14px;
  border-radius: 0 0 6px 6px;
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.hot_line .content {
  margin: 10px;
  margin-left: 150px;
}
.hot_line .content .title {
  font-size: 1.2rem;
  color: #2c92ff;
  padding: 5px 0;
}
.hot_line .content .cansu span {
  display: inline-block;
  width: 50%;
  line-height: 1.6rem !important;
  color: #666666;
}
.hot_line .jindian {
  color: #999999;
  padding: 5px 0;
}

.hot_line .btn {
  border-radius: 5px;
  background: #ff8800;
  color: #ffffff;
  font-size: 1rem;
  position: absolute;
  z-index: 1;
  bottom: 10px;
  right: 10px;
  padding: 0 10px;
  line-height: 2rem;
}
.price_info {
  position: absolute;
  z-index: 1;
  left: 150px;
  bottom: 10px;
}
</style>
  