<template>
  <div class="hello" style="padding-bottom: 80px">
    <van-sticky v-if="catalog">
      <van-nav-bar
        style="background: #1989fa; color: #ffffff"
        @click-left="$goBacks()"
        @click-right="onClickRight"
      >
        <template #title
          ><span style="color: #ffffff">{{ catalog.title }}</span></template
        >
        <template #left
          ><span style="color: #ffffff">
            <van-icon
              name="arrow-left"
              style="color: #ffffff; margin-right: 6px"
            />返回</span
          ></template
        >
        <template #right
          ><span style="color: #ffffff">
            <van-icon
              name="wap-home-o"
              style="color: #ffffff; font-size: 1.6rem" /></span
        ></template>
      </van-nav-bar>
    </van-sticky>
    <template v-if="catalog">
      <van-list
        :loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getList"
      >
        <!-- faq -->
        <van-collapse
          v-model="activeNames"
          accordion
          v-if="catalog.tag == 'backfaq'"
        >
          <van-collapse-item
            :title="item.title"
            :name="idx"
            v-for="(item, idx) in dataList"
            :key="idx"
          >
            <template #title>
              <div>
                <van-icon name="question-o" style="color: #009900" />
                {{ item.title }}
              </div>
            </template>
            <div
              class="dibb"
              style="font-size: 12px; line-height: 24px; color: #333"
              v-html="item.content ? item.content : '没有回复'"
            ></div>
          </van-collapse-item>
        </van-collapse>
        <div
          class="box"
          v-if="
            catalog.tag == 'news' ||
            catalog.tag == 'guide' ||
            catalog.tag == 'faq'
          "
        >
          <pro-item
            type="news_list"
            v-for="(item, idx) in dataList"
            :item="item"
            :key="idx"
            @click="viewItem(item.id)"
            style="margin: 0 10px"
          ></pro-item>
        </div>
        <div class="box" v-else>
          <div
            class="item"
            v-for="item in dataList"
            @click="viewItem(item.id)"
            :key="item.id"
          >
            <span>{{ item.title }}</span>
            <span class="time">{{ item.times }}</span>
          </div>
        </div>
      </van-list>
    </template>
    <in-load v-else />
    <my-footer
      :view="hasLoad"
      :showElse="false"
      :showTabBar="false"
    ></my-footer>
    <share-box v-if="loadShare" :shareData="shareData"></share-box>
  </div>
</template>
<script>
import { ref } from "vue";
import { showToast } from "vant";
import MyFooter from "@/components/myFooter.vue";
import ProItem from "@/components/ProItem.vue";
import ShareBox from "@/components/ShareBox.vue";
import InLoad from "../../components/InLoad.vue";

export default {
  name: "catalogList",
  components: { MyFooter, ProItem, ShareBox, InLoad },
  data() {
    return {
      hasLoad: false,
      catalog: false,
      activeNames: -1,
      tags: false,
      loading: false,
      finished: false,
      error: false,
      dataList: [],
      per_page: 10,
      last_page: 0,
      current_page: 0,
      total: 0,
      loadShare: false,
      shareData: false,
    };
  },
  setup() {
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
  mounted() {
    this.tags =
      this.$route.params.tags != null && this.$route.params.tags
        ? this.$route.params.tags
        : false;
    if (!this.tags) {
      showToast("页面不存在");
      setTimeout(() => {
        history.back();
      }, 1500);
      return;
    }
    this.getDetail();
  },
  methods: {
    onClickRight() {
      this.$router.push("/");
    },
    viewItem(item) {
      if (!item) return;
      let url = "/article/detail/" + item;
      this.$router.push(url);
    },
    getDetail() {
      this.$http
        .getDetail({
          table: "NoticeType",
          tag: this.tags,
        })
        .then((res) => {
          this.catalog = res;
          document.title = res.title;
          this.loadShare = true;
        })
        .catch((error) => {
          console.log(error);
          history.go(-1);
        });
    },
    getList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$http
        .getList({
          table: "Notice",
          hide_success: true,
          typeid: this.catalog ? this.catalog.id : 0,
          pageInfo: {
            page_size: this.per_page,
          },
          page: this.current_page + 1,
        })
        .then((res) => {
          this.loading = false;
          this.hasLoad = true;
          res.data.map((item) => {
            this.dataList.push(item);
          });
          this.current_page = res.current_page;
          this.total = res.total;
          if (res.last_page == res.current_page || res.last_page == 0) {
            this.finished = true;
            return;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.finished = true;
        });
    },
  },
};
</script>
  <style scoped>
.content {
  margin: 20px;
  font-size: 12px;
  line-height: 24px;
}
.tops {
  text-align: center;
  margin: 20px;
}
.tops .title {
  font-size: 16px;
  line-height: 30px;
}
.tops .info {
  font-size: 12px;
  line-height: 30px;
  color: #666666;
}
.box {
  margin: 10px 0;
}
.item {
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #f3f3f3;
}
.item {
  font-size: 14px;
}
</style>
  