<template>
  <div class="hello">
    <in-load v-if="loading"></in-load>
    <template v-else>
      <van-sticky>
        <div class="topNav" v-if="logo">
          <van-image :src="logo" class="logo"></van-image>
        </div>
      </van-sticky>
      <div v-for="(obj, idx) in componentsArr" :key="idx">
        <!-- 宫格排列 -->
        <my-box
          :title="obj.title"
          :hideTitle="obj.config.hideTitle"
          :hideBorder="obj.config.hideBorder"
          :needMargin="obj.config.needMargin"
          :backgroundColor="obj.config.backgroundColor"
        >
          <template #title>
            <template v-if="obj.titleConfig && obj.titleConfig.icon">
              <!-- 是否动画 -->
              <nut-animate
                v-if="obj.titleConfig.animate"
                :type="obj.titleConfig.animate"
                loop
              >
                <van-icon
                  v-if="obj.titleConfig.type == 'van'"
                  :name="obj.titleConfig.icon"
                  :style="{
                    color: obj.titleConfig.color
                      ? obj.titleConfig.color
                      : '#cc0000',
                    fontSize: obj.titleConfig.size
                      ? obj.titleConfig.size
                      : '20px',
                    margin: obj.titleConfig.margin
                      ? obj.titleConfig.margin
                      : '0 6px 0 0',
                  }"
                />
                <IconFont
                  v-else-if="obj.titleConfig.type == 'nut'"
                  :name="obj.titleConfig.icon"
                  :style="{
                    color: obj.titleConfig.color
                      ? obj.titleConfig.color
                      : '#cc0000',
                    fontSize: obj.titleConfig.size
                      ? obj.titleConfig.size
                      : '20px',
                    margin: obj.titleConfig.margin
                      ? obj.titleConfig.margin
                      : '0 6px 0 0',
                  }"
                ></IconFont>
              </nut-animate>
              <template v-else>
                <!-- 没有动画 -->
                <van-icon
                  v-if="obj.titleConfig.type == 'van'"
                  :name="obj.titleConfig.icon"
                  :style="{
                    color: obj.titleConfig.color
                      ? obj.titleConfig.color
                      : '#cc0000',
                    fontSize: obj.titleConfig.size
                      ? obj.titleConfig.size
                      : '20px',
                    margin: obj.titleConfig.margin
                      ? obj.titleConfig.margin
                      : '0 6px 0 0',
                  }"
                />
                <IconFont
                  v-else-if="obj.titleConfig.type == 'nut'"
                  :name="obj.titleConfig.icon"
                  :style="{
                    color: obj.titleConfig.color
                      ? obj.titleConfig.color
                      : '#cc0000',
                    fontSize: obj.titleConfig.size
                      ? obj.titleConfig.size
                      : '20px',
                    margin: obj.titleConfig.margin
                      ? obj.titleConfig.margin
                      : '0 6px 0 0',
                  }"
                ></IconFont>
              </template>
            </template>
            {{ obj.title }}
          </template>
          <template #content>
            <template v-if="obj.type == 'grid'">
              <!-- 菜单 -->
              <van-grid
                v-if="obj.tag == 'entery'"
                :square="obj.config.square"
                :border="obj.config.border"
                :icon-size="obj.config.icon_size ? obj.config.icon_size : ''"
                :column-num="obj.config.column ? obj.config.column : 4"
              >
                <van-grid-item
                  v-for="(item, i) in obj.content"
                  :key="i"
                  @click="goUrl(item.url)"
                >
                  <!-- 自定义 -->
                  <div class="entery_item">
                    <div
                      class="img"
                      :style="{
                        background: item.icon_color
                          ? item.icon_color
                          : '#f3f3f3',
                        fontSize: item.icon_size ? item.icon_size : '2rem',
                        width: item.size ? item.size : '4rem',
                        height: item.size ? item.size : '4rem',
                      }"
                    >
                      <nut-animate
                        v-if="item.animate"
                        :type="item.animate"
                        loop
                      >
                        <font-awesome-icon
                          v-if="item.iconType == 'font'"
                          :icon="item.icon"
                        />
                        <van-icon v-else :name="item.icon" />
                      </nut-animate>
                      <template v-else>
                        <font-awesome-icon
                          v-if="item.iconType == 'font'"
                          :icon="item.icon"
                        />
                        <van-icon v-else :name="item.icon" />
                      </template>
                    </div>
                    <div>{{ item.title }}</div>
                  </div>
                </van-grid-item>
              </van-grid>
              <!-- 游船系列 -->
              <van-grid
                v-else
                :square="obj.config.square"
                :border="obj.config.border"
                :icon-size="obj.config.icon_size ? obj.config.icon_size : ''"
                :column-num="obj.config.column ? obj.config.column : 4"
              >
                <template v-for="(item, i) in obj.content" :key="i">
                  <van-grid-item
                    :icon="
                      item.logo
                        ? item.logo +
                          '?x-oss-process=image/resize,m_mfit,w_100,h_100'
                        : 'photo-o'
                    "
                    :text="item.title"
                    icon-color="#999"
                    @click="goUrl(item.url)"
                  />
                </template>
                <!-- <van-grid-item
                icon="add-o"
                text="更多"
                icon-color="#999"
                v-if="obj.content.length > 6"
                @click="goUrl('/boat/type/9999')"
              /> -->
              </van-grid>
            </template>
            <!-- banner -->
            <template v-else-if="obj.type == 'banner'">
              <van-swipe :autoplay="3000" lazy-render>
                <van-swipe-item v-for="(item, i) in obj.content" :key="i">
                  <van-image
                    fit="cover"
                    :src="item.url"
                    width="100%"
                    :height="obj.height"
                  ></van-image>
                  <div>{{ obj.banner_height }}</div>
                </van-swipe-item>
              </van-swipe>
            </template>
            <!-- 公告 -->
            <template v-else-if="obj.type == 'notice'">
              <!-- 滚动公告 -->

              <!-- 京东风格 -->
              <!-- <nut-noticebar :scrollable="false">
              <template #left-icon>
                <img
                  src="https://img13.360buyimg.com/imagetools/jfs/t1/72082/2/3006/1197/5d130c8dE1c71bcd6/e48a3b60804c9775.png"
                  style="width: 20px; height: 20px"
                />
              </template>
              <a href="https://www.jd.com">京东商城</a>
            </nut-noticebar> -->
              <!-- 多行滚动 -->
              <!-- <nut-noticebar
              direction="vertical"
              v-if="obj.tag == 'a'"
              :list="obj.content"
              :speed="10"
              :stand-time="2000"
              @click="handleClick"
            /> -->

              <van-notice-bar
                v-if="obj.tag == 'a'"
                left-icon="volume-o"
                :color="obj.color"
                :background="obj.background"
                :scrollable="false"
              >
                <van-swipe
                  vertical
                  class="notice-swipe"
                  :autoplay="obj.times ? obj.times : 3000"
                  :touchable="false"
                  :show-indicators="false"
                >
                  <van-swipe-item
                    v-for="(item, i) in obj.content"
                    :key="i"
                    @click="viewNotice(item)"
                    >{{ item.title }}</van-swipe-item
                  >
                </van-swipe>
              </van-notice-bar>
              <van-notice-bar
                left-icon="volume-o"
                v-if="obj.tag == 'b'"
                :text="obj.content"
              />
            </template>
            <!-- 电话 -->
            <!-- <template v-else-if="obj.type == 'callPhone'">
            <div class="callphone">
              <div @click="callPhone(obj.phone)">
                <van-icon name="phone-o" style="color: #ff0000" />
                {{ obj.phone_title }} | <b>{{ obj.phone }}</b>
              </div>
              <div @click="goUrl(obj.chat.url)">
                <van-icon name="chat-o" dot /> {{ obj.chat.title }}
              </div>
            </div>
          </template> -->
            <!-- 搜索面板 -->
            <template v-else-if="obj.type == 'search_box'">
              <div class="search_box">
                <div class="title">
                  <div
                    class="item"
                    :class="{
                      item_act: searchForm.active == 1,
                      item_l1: searchForm.active == 2,
                    }"
                    @click="chageSearch(1)"
                  >
                    查船期
                  </div>
                  <div
                    class="item"
                    :class="{
                      item_act: searchForm.active == 2,
                      item_l2: searchForm.active == 1,
                    }"
                    @click="chageSearch(2)"
                  >
                    查游船
                  </div>
                </div>
                <div class="search_line" v-if="searchForm.active == 1">
                  <div class="tools" @click="showSelectLine">
                    <span class="start">
                      {{ searchForm.up_title }}
                      <!-- <van-popover @select="selectUpMatou" :actions="matou">
                      <template #reference>
                        {{ searchForm.up_title }}
                      </template>
                    </van-popover> -->
                    </span>
                    <span class="end">
                      {{ searchForm.down_title }}
                      <!-- <van-popover @select="selectDownMatou" :actions="matou">
                      <template #reference>
                        {{ searchForm.down_title }}
                      </template>
                    </van-popover> -->
                    </span>
                    <div class="trans">
                      <font-awesome-icon icon="repeat" />
                    </div>
                  </div>
                  <div class="input_date">
                    日期 <span @click="showDateBox">{{ searchForm.date }}</span>
                  </div>
                  <div style="padding-top: 10px">
                    <van-button @click="searchDo" type="primary" block
                      >立即查询</van-button
                    >
                  </div>
                  <div class="hot">
                    <span
                      v-for="(item, i) in obj.content.search_line"
                      :key="i"
                      @click="quickSearchLine(item)"
                      >{{ item.title }}</span
                    >
                  </div>
                </div>
                <div class="search_line" v-if="searchForm.active == 2">
                  <van-field v-model="searchForm.keyword" center size="large">
                    <template #input>
                      <input
                        type="text"
                        placeholder="输入游船名称"
                        v-model="searchForm.keyword"
                        class="inputs"
                      />
                      <van-button
                        @click="searchDo"
                        style="
                          margin: 0;
                          padding: 0 10px;
                          width: 90px;
                          border-radius: 0 6px 6px 0;
                          height: 40px;
                          line-height: 40px;
                          font-size: 14px;
                        "
                        size="large"
                        type="primary"
                        >查询</van-button
                      >
                    </template>
                  </van-field>
                  <div style="margin-top: 10px">
                    <div class="stitle" style="height: 30px; line-height: 30px">
                      推荐游轮
                    </div>
                    <van-tag
                      type="success"
                      style="margin-right: 4px; margin-top: 5px"
                      v-for="(item, i) in obj.content.search_boat"
                      :key="i"
                      @click="goUrl(item.url)"
                      size="large"
                      plain
                      >{{ item.title }}</van-tag
                    >
                  </div>
                </div>
              </div>
            </template>
            <!-- 航线定制展示 -->
            <template v-else-if="obj.type == 'line_box'">
              <!-- 热门游轮 -->
              <template v-if="obj.tag == 'hot_boat'">
                <Scrollbar :horizontal="true">
                  <div
                    style="
                      min-width: 100%;
                      overflow: visible;
                      padding-bottom: 5px;
                      display: flex;
                    "
                  >
                    <pro-item
                      type="boat_a"
                      v-for="(item, i) in obj.content"
                      :key="i"
                      :item="item"
                    ></pro-item>
                  </div>
                </Scrollbar>
              </template>
              <!-- 热门线路 -->
              <template v-else-if="obj.tag == 'hot_line'">
                <van-row gutter="10">
                  <van-col span="12" v-for="(item, i) in obj.content" :key="i">
                    <pro-item type="line_a" :item="item"></pro-item>
                  </van-col>
                </van-row>
              </template>
              <!-- 推荐线路 -->
              <template v-else-if="obj.tag == 'tuijian_line'">
                <pro-item
                  type="line_b"
                  v-for="(item, idx) in obj.content"
                  :item="item"
                  :key="idx"
                ></pro-item>
              </template>
              <!-- 其他 -->
              <van-cell-group v-else>
                <van-cell
                  v-for="(item, i) in obj.content"
                  :key="i"
                  :title="item.title"
                />
              </van-cell-group>
            </template>
            <!-- 资讯 -->
            <template v-else-if="obj.type == 'news_box'">
              <pro-item
                type="news_list"
                v-for="(item, idx) in obj.content"
                :item="item"
                :key="idx"
              ></pro-item>
            </template>
          </template>
        </my-box>
      </div>
      <van-calendar
        v-model:show="showdate"
        :show-confirm="false"
        @confirm="onConfirm"
        switch-mode="year-month"
        style="height: 520px"
      />
      <van-popup v-model:show="viewSelectLine" round position="bottom">
        <van-picker
          :columns="boatLineArr"
          @cancel="viewSelectLine = false"
          @confirm="onConfirmLine"
        />
      </van-popup>
      <my-footer :view="showFooter" :showTabBar="true"></my-footer>
      <share-box v-if="loadShare" :shareData="shareData"></share-box>
      <div class="van-safe-area-bottom"></div>
    </template>
  </div>
</template>
  
<script>
import { showConfirmDialog, showToast } from "vant";
import MyBox from "@/components/MyBox.vue";
import MyFooter from "@/components/myFooter.vue";
import ProItem from "@/components/ProItem.vue";
import wx from "weixin-js-sdk"; // 引入微信JS-SDK库
import { Scrollbar } from "vue-amazing-ui";
import ShareBox from "@/components/ShareBox.vue";
import InLoad from "@/components/InLoad.vue";

import "vue-amazing-ui/css";

export default {
  name: "index",
  components: {
    MyBox,
    MyFooter,
    Scrollbar,
    ProItem,
    ShareBox,
    InLoad,
  },
  data() {
    return {
      logo: false,
      userInfo: false,
      loading: false,
      showMatou: false,
      loadShare: false,
      shareData: false,
      viewSelectLine: false,
      selectLineAct: "up",
      searchActive: 0,
      componentsArr: [],
      boatLineArr: [],
      showdate: false,
      showFooter: false,
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      },
      device: false,
      searchForm: {
        active: 1,
        keyword: "",
        ns: 0,
        up_id: 0,
        down_id: 0,
        type_id: 0,
        line_title: "全部航线",
        up_title: "全部",
        down_title: "全部",
        type_title: "全部游轮",
        up: {
          id: 0,
          text: "重庆",
        },
        down: {
          id: 0,
          text: "宜昌",
        },
        date: "",
        lid: 0,
        bid: 0,
        st: "day",
      },
    };
  },
  mounted() {
    this.device = localStorage.getItem("device");
    this.init();
  },
  methods: {
    showSelectLine() {
      this.viewSelectLine = true;
    },
    onConfirmLine(selectedValues) {
      this.searchForm.up_id =
        selectedValues.selectedOptions[0].matou.up_info.id;
      this.searchForm.up_title =
        selectedValues.selectedOptions[0].matou.up_info.title;
      this.searchForm.down_id =
        selectedValues.selectedOptions[0].matou.down_info.id;
      this.searchForm.down_title =
        selectedValues.selectedOptions[0].matou.down_info.title;
      this.viewSelectLine = false;
      this.searchForm.line_id = selectedValues.selectedOptions[0].id;
      this.searchForm.line_title = selectedValues.selectedOptions[0].title;
    },
    selectUpMatou(item) {
      this.searchForm.up_id = item.id;
      this.searchForm.up_title = item.title;
    },
    selectDownMatou(item) {
      this.searchForm.down_id = item.id;
      this.searchForm.down_title = item.title;
    },
    showDateBox() {
      this.showdate = true;
    },
    onConfirm(date) {
      let m = date.getMonth() + 1;
      let d = date.getDate();
      this.searchForm.date = `${date.getFullYear()}-${m < 10 ? "0" + m : m}-${
        d < 10 ? "0" + d : d
      }`;
      this.showdate = false;
    },
    changeStartEnd() {
      let a = this.searchForm.up;
      let b = this.searchForm.down;
      this.searchForm.up = b;
      this.searchForm.down = a;
    },
    chageSearch(val) {
      this.searchForm.active = val;
    },
    init() {
      this.loading = true;
      this.$http
        .postData("/Boat/initData", {
          hide_success: true,
        })
        .then((res) => {
          this.componentsArr = res.list;
          this.matou = res.matou;
          this.searchForm.date = res.date;

          res.boatLineArr.map((item) => {
            if (item.title == "重庆") {
              this.searchForm.up = item;
            }
            if (item.title == "宜昌") {
              this.searchForm.down = item;
            }
          });

          this.searchForm.up_id = res.boatLineArr[0].matou.up_info.id;
          this.searchForm.up_title = res.boatLineArr[0].matou.up_info.title;
          this.searchForm.down_id = res.boatLineArr[0].matou.down_info.id;
          this.searchForm.down_title = res.boatLineArr[0].matou.down_info.title;
          this.viewSelectLine = false;
          this.searchForm.line_id = res.boatLineArr[0].id;
          this.searchForm.line_title = res.boatLineArr[0].title;
          res.list.map((item) => {
            if (item.type == "search_box") {
              localStorage.setItem(
                "hotBoat",
                JSON.stringify(item.content.search_boat)
              );
            }
          });
          let typeArr = [];
          res.types.map((item) => {
            typeArr.push({
              id: item.id,
              title: item.title,
            });
          });
          localStorage.setItem("webTitle", res.webTitle);
          localStorage.setItem("typeArr", JSON.stringify(typeArr));
          if (res.defaultShare) {
            localStorage.setItem(
              "defaultShare",
              JSON.stringify(res.defaultShare)
            );
          } else {
            localStorage.removeItem("defaultShare");
          }

          // localStorage.setItem("matouArr", JSON.stringify(res.matou));
          localStorage.setItem("boatLineArr", JSON.stringify(res.boatLineArr));
          localStorage.setItem("cardTypeArr", JSON.stringify(res.cardTypeArr));

          localStorage.setItem("tabData", JSON.stringify(res.barArr));
          this.showFooter = true;

          localStorage.setItem(
            "footer_links",
            JSON.stringify(res.footer_links)
          );
          localStorage.setItem("copyright", res.copyright);
          localStorage.setItem("order_tips", res.order_tips);
          localStorage.setItem("room_tips", res.room_tips);

          this.boatLineArr = res.boatLineArr;
          this.loadShare = true;
          this.logo = res.logo ? res.logo : false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    goUrl(url) {
      if (!url) return;
      if (url == "not") {
        this.$notFound();
        return;
      }
      if (url.indexOf("tel:") >= 0) {
        window.location.href = "tel://" + url.split(":")[1];
        return;
      }
      if (url.indexOf("http") > -1) {
        window.location.href = url;
        return;
      }
      this.$router.push(url);
    },
    callPhone(phoneNumber) {
      if (phoneNumber) {
        window.location.href = "tel://" + phoneNumber;
      }
    },
    viewNotice(item) {
      this.goUrl(item.to_url ? item.to_url : item.url_font);
    },
    quickSearchLine(item) {
      this.searchForm.up_id = item.matou.up_info.id;
      this.searchForm.up_title = item.matou.up_info.title;
      this.searchForm.down_id = item.matou.down_info.id;
      this.searchForm.down_title = item.matou.down_info.title;
      this.searchForm.line_title = item.title;
      this.searchForm.line_id = item.id;
      this.searchForm.active = 1;
      this.searchDo();
    },
    searchDo() {
      localStorage.setItem("searchData", JSON.stringify(this.searchForm));
      if (this.searchForm.active == 1) {
        this.$router.push({
          name: "shipMent",
          query: {
            q: "quick",
          },
        });
      } else {
        if (!this.searchForm.keyword) {
          showToast("请填写关键词");
          return;
        }
        this.$router.push({
          path: "/search",
          query: {
            kwd: this.searchForm.keyword,
          },
        });
      }
    },
  },
};
</script>
  <style scoped>
.hello {
  padding-bottom: 60px;
}
.entery_item {
  text-align: center;
  font-size: 1rem;
  line-height: 1.6rem;
}
.entery_item .img {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto;
  color: #ffffff;
}

.search_box {
  position: relative;
  min-height: 100px;
  border-radius: 10px;
  /* box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.2); */
  padding-top: 10px;
}
.search_box .title {
  height: 40px;
  border-radius: 10px 10px 0 0;
  background: #ffffff;
  /* border: 1px solid #eaeaea; */
  border-bottom: none;
  position: absolute;
  z-index: 1;
  top: -40px;
  left: 0px;
  right: 0px;
  overflow: hidden;
}
.search_box .title .item {
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #1989fa;
  color: #ffffff;
  width: 50%;
  float: left;
  font-size: 1.4rem;
}
.item_l1 {
  border-radius: 0 0 6px 0;
}
.item_l2 {
  border-radius: 0 0 0 6px;
}
.search_box .title .item_act {
  background: #ffffff;
  color: #1989fa;
  font-weight: 600;
}
.search_line {
  padding: 10px 20px;
}
.search_line .input_date {
  font-size: 1.5rem;
  padding: 10px 20px;
}
.search_line .input_date span {
  padding-left: 10px;
  color: #000000;
}
.search_line .tools {
  position: relative;
  font-size: 1.5rem;
  padding: 10px;
  border-bottom: 1px solid #dedede;
}
.search_line .trans {
  width: 50px;
  margin: 0 auto;
  text-align: center;
  color: #1989fa;
}
.search_line .start {
  position: absolute;
  left: 20px;
  top: 10px;
  font-weight: 500;
}
.search_line .end {
  position: absolute;
  right: 20px;
  top: 10px;
  font-weight: 500;
}
.search_line .hot {
  margin: 10px 0;
  line-height: 30px;
  color: #cc0000;
  font-size: px;
  height: 30px;
  overflow: hidden;
}
.search_line .hot span {
  margin: 0 5px;
  color: #666666;
}

.notice-swipe {
  height: 40px;
  line-height: 40px;
}
.topNav {
  background: #1989fa;
  text-align: center;
}
.topNav .logo {
  width: 300px;
  margin: 10px auto;
}
.topNav .wtitle {
  font-size: 16px;
  font-weight: 500;
}

.callphone {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #f3f3f3;
  padding: 0 10px;
}
.callphone b {
  color: #ff0000;
  font-weight: 600;
}

.jt {
  border-bottom: 1px solid #2c92ff;
  position: absolute;
  left: 34%;
  right: 34%;
  top: 16px;
  display: flex;
  justify-content: right;
}
.jt .arrow {
  position: relative;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 0px 10px;
  border-color: transparent transparent transparent #2c92ff;
  /* transform: rotate(45deg); */
}
.inputs {
  border: 1px solid #dedede;
  border-right: none;
  border-radius: 6px 0 0 6px;
  padding: 0 0 0 10px;
  height: 38px;
  line-height: 38px;
  color: #333333;
  width: 100%;
}

.seamless-warp {
  overflow: hidden;
  white-space: nowrap;
}
.seamless-warp .item {
  display: inline-block;
  margin-right: 10px;
}
</style>
  