<template>
  <div class="hello">
    <template v-if="hasLoad">
      <van-sticky>
        <van-nav-bar
          style="background: #1989fa; color: #ffffff"
          @click-left="$goBacks()"
          @click-right="onClickRight"
        >
          <template #title
            ><span style="color: #ffffff">{{ pro.nav_title }}</span></template
          >
          <template #left
            ><span style="color: #ffffff">
              <van-icon
                name="arrow-left"
                style="color: #ffffff; margin-right: 6px"
              />返回</span
            ></template
          >
          <template #right
            ><span style="color: #ffffff">
              <van-icon
                name="wap-home-o"
                style="color: #ffffff; font-size: 1.6rem" /></span
          ></template>
        </van-nav-bar>
      </van-sticky>
      <div class="headbox" ref="headbox">
        <van-swipe :autoplay="3000" lazy-render style="height: 225px">
          <van-swipe-item v-for="(item, i) in pro.imgs" :key="i">
            <van-image
              fit="cover"
              position="center"
              :src="item.url + '?x-oss-process=image/resize,m_mfit,w_300,h_225'"
              style="width: 100%; height: 225px"
            ></van-image>
          </van-swipe-item>
        </van-swipe>
        <div class="htm">
          <div class="title">
            <b>{{ pro.title }}</b>
          </div>
          <div class="stitle" v-if="detail.stitle">{{ detail.stitle }}</div>
          <div class="infos">
            <van-row gutter="10">
              <van-col span="12">
                <div class="item">
                  游船 <span>{{ detail.boat_info.title }}</span>
                </div>
              </van-col>
              <van-col span="12">
                <div class="item">
                  星级 <span>{{ detail.star || "-" }}</span>
                </div>
              </van-col>
              <van-col span="12">
                <div class="item">
                  类型 <span>{{ detail.trans_txt }}</span>
                </div>
              </van-col>
              <van-col span="12">
                <div class="item">
                  时长 <span>{{ detail.days }}</span>
                </div>
              </van-col>
            </van-row>
          </div>
          <div v-if="detail.tags.length" style="padding: 6px 0">
            <van-tag
              v-for="(item, idx) in detail.tags"
              :type="idx % 2 ? 'warning' : 'success'"
              :key="idx"
              style="margin-right: 4px"
              >{{ item }}</van-tag
            >
          </div>
          <div class="price" v-if="detail.mins > 0">
            <small>￥</small>{{ detail.mins }}<small>起</small>
          </div>
          <div class="price" v-else>电询</div>
          <div class="notice" v-if="detail.tips">
            <van-icon name="info-o" /> {{ detail.tips }}
          </div>
        </div>
      </div>
      <div class="topMain">
        <div class="select_box">
          <div class="select_title">
            选择航线<small><van-icon name="info-o" />滑动展示更多</small>
            <span class="icon" @click="goUrl(detail.phone)">
              <nut-animate type="breath" loop>
                <font-awesome-icon icon="phone" />
                <span>电话咨询</span>
              </nut-animate>
            </span>
          </div>
          <div class="select_con" v-if="detail.lineArr.length">
            <div class="items">
              <span
                class="item"
                :class="line.id == sid ? 'active' : ''"
                v-for="(line, idx) in detail.lineArr"
                @click="selectLine(line.id)"
                :key="idx"
                style="
                  display: flex;
                  justify-content: space-between;
                  font-size: 1rem;
                  min-width: 110px;
                "
              >
                <span>
                  <span>{{ line.matou.up_info.title }}</span
                  ><br />
                  <span>{{ line.matou.up_info.times }}</span>
                </span>
                <span style="line-height: 30px; font-size: 1.4rem"
                  ><van-icon name="arrow"
                /></span>
                <span>
                  <span>{{ line.matou.down_info.title }}</span
                  ><br />
                  <span>{{ line.matou.down_info.times }}</span>
                </span>
              </span>
            </div>
          </div>
          <div v-else>
            <div class="not">没有出行计划</div>
          </div>
        </div>
        <div class="select_box">
          <div class="select_title">
            出行日期<small><van-icon name="info-o" />滑动展示更多</small>

            <span class="icon" @click="goUrl(detail.chat_url)">
              <nut-animate type="breath" loop>
                <van-icon name="service-o" style="font-size: 1.2rem" />
                <span>在线咨询</span>
              </nut-animate>
            </span>
          </div>
          <div class="select_con" v-if="detail.dateArr">
            <div class="items">
              <span
                class="item"
                @click="selectDay(idx)"
                :class="day.date != date ? '' : 'active'"
                v-for="(day, idx) in detail.dateArr"
                :key="idx"
              >
                <div>{{ day.date.split("-").splice(1).join("-") }}</div>
                <div style="color: #ff9900">
                  ￥<span style="font-size: 1rem">{{ day.price }}</span>
                </div>
              </span>
            </div>
          </div>
          <div v-else>
            <div class="not">没有出行计划</div>
          </div>
        </div>
        <div class="roomBox" v-if="roomArr">
          <div class="rtitle">房型价格</div>
          <template v-for="(item, idx) in roomArr.room_list" :key="idx">
            <div class="item" @click="viewRoomInfo(item)">
              <div>
                <van-image
                  fit="cover"
                  position="center"
                  :src="
                    item.thumb +
                    '?x-oss-process=image/resize,m_mfit,w_150,h_150'
                  "
                  class="thumb"
                >
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </div>
              <div class="con">
                <div class="title">{{ item.title }}</div>
                <div class="cs">
                  <template v-for="cs in item.content">
                    <div v-if="checkRcs(cs.title)">
                      {{ checkRcs(cs.title) }}:{{ cs.value }}
                    </div>
                  </template>
                </div>
                <span class="rights">
                  <span
                    class="price"
                    v-if="
                      item.price_list[0].value &&
                      parseInt(item.price_list[0].value) > 0
                    "
                    >￥<b>{{ item.price_list[0].value }}</b
                    >/成人</span
                  >
                  <span class="price" v-else><b>电询</b></span>
                  <div class="views">
                    <van-button type="warning" size="small"
                      >立即预订</van-button
                    >
                  </div>
                </span>
              </div>
            </div>
          </template>
          <div class="room_tips" v-if="room_tips">
            <div class="title"><van-icon name="info-o" /> 温馨提醒:</div>
            <div class="info" v-html="room_tips"></div>
          </div>
        </div>
        <!-- 没有出行价格 -->
        <div class="roomBox" v-else>
          <div class="rtitle">房型价格</div>
          <div class="not">没有出行计划</div>
          <!-- <div class="item" v-for="(item, idx) in detail.room_list" :key="idx">
            <div class="thumb">
              <van-image
                fit="cover"
                position="center"
                :src="item.thumb"
                style="height: 100%"
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
            </div>
            <div class="con">
              <div class="title">{{ item.title }}</div>
              <div class="cs">
                <template v-for="cs in item.content">
                  <div v-if="checkRcs(cs.title)">
                    {{ checkRcs(cs.title) }}:{{ cs.value }}
                  </div>
                </template>
              </div>
              <span class="rights">
                <span class="price">￥<b>电询</b></span>
                <div class="views">
                  <font-awesome-icon icon="search-plus" />房型详情
                  <van-button
                    type="warning"
                    size="small"
                    @click="showToast('不可预订')"
                    >预订</van-button
                  >
                </div>
              </span>
            </div>
          </div> -->
        </div>
      </div>
      <div>
        <template>
          <p v-for="(sconfig, ix) in detail.show_config" :key="ix">
            {{ sconfig.title }}
          </p>
        </template>
      </div>
      <div class="dibb" v-if="hasLoad && detail">
        <van-tabs v-model:active="tabActive" scrollspy sticky>
          <div v-for="(sconfig, ism) in detail.show_config" :key="ism">
            <van-tab
              :title="sconfig.title"
              v-if="sconfig.name == 'boat' && sconfig.status"
            >
              <div class="box">
                <div class="box_title">游轮介绍</div>
                <!-- 参数 -->
                <template v-for="(child, sbc) in detail.show_config">
                  <div
                    class="cansu"
                    v-if="child.name == 'cansu' && child.status == 1"
                    :key="sbc"
                  >
                    <van-row gutter="10">
                      <van-col
                        span="12"
                        v-for="(item, i) in detail.boat_info.cs"
                        :key="i"
                      >
                        <div class="item_cs">
                          {{ item.title }}：{{ item.value ? item.value : "-" }}
                        </div>
                      </van-col>
                    </van-row>
                  </div>
                </template>
                <!-- 视频 -->
                <div
                  class="boat_video"
                  v-if="detail.boat_info && detail.boat_info.video"
                >
                  <video
                    ref="myPlayer"
                    :src="detail.boat_info.video"
                    t7-video-player-ext="true"
                    webkit-playsinline="true"
                    x5-playsinline="true"
                    autoplay
                    controls
                    v-if="viewBoatVideo"
                    style="width: 100%; height: 240px"
                  ></video>
                  <div class="video_box" v-else>
                    <van-image
                      :src="
                        detail.boat_info.thumb +
                        '?x-oss-process=image/resize,m_mfit,w_400,h_400'
                      "
                      style="height: 100%; width: 100%"
                      fit="cover"
                      position="center"
                    />
                    <span class="play">
                      <van-icon
                        @click="viewBoatVideo = true"
                        name="play-circle-o"
                      />
                    </span>
                  </div>
                </div>
                <!-- 文字 -->
                <div
                  class="content"
                  v-if="detail.boat_info && detail.boat_info.content"
                  v-html="detail.boat_info.content"
                ></div>
                <div class="not" v-else>没有填写</div>
              </div>
            </van-tab>
            <van-tab
              :title="sconfig.title"
              v-if="sconfig.name == 'xingcheng' && sconfig.status"
            >
              <div class="box">
                <div class="box_title">行程安排</div>
                <div class="xingcheng" v-if="detail.content.length">
                  <div
                    class="item"
                    v-for="(day, idx) in detail.content"
                    :key="idx"
                  >
                    <div style="padding: 0 16px">
                      <div class="title">
                        Day{{ idx + 1
                        }}{{ day.title ? " " + day.title : " 行程" }}
                      </div>
                      <div class="infos">
                        <div class="icon">
                          <font-awesome-icon icon="cutlery" />
                        </div>
                        <div v-if="day.canyin">
                          <div>
                            早餐：{{ day.canyin.zao ? day.canyin.zao : "自理" }}
                          </div>
                          <div>
                            午餐：{{ day.canyin.wu ? day.canyin.wu : "自理" }}
                          </div>
                          <div>
                            晚餐：{{ day.canyin.wan ? day.canyin.wan : "自理" }}
                          </div>
                        </div>
                      </div>
                      <div class="infos">
                        <div class="icon" style="margin-right: 4px">
                          <font-awesome-icon icon="bed" />
                        </div>
                        <div>
                          {{ day.hotel ? day.hotel : detail.btitle }}
                        </div>
                      </div>
                      <div class="infos">
                        <div class="icon" style="padding-top: 5px">
                          <font-awesome-icon icon="flag" />
                        </div>
                        <div class="text" v-html="day.content"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="xingcheng" v-else>
                  <div class="not">暂无行程记录</div>
                </div>
              </div>
            </van-tab>
            <van-tab
              :title="sconfig.title"
              v-if="sconfig.name == 'feiyong' && sconfig.status"
              ><div class="box">
                <div class="box_title">费用说明</div>
                <div class="content" v-html="detail.price_info"></div>
              </div>
            </van-tab>
            <van-tab
              :title="sconfig.title"
              v-if="sconfig.name == 'tuigai' && sconfig.status"
              ><div class="box">
                <div class="box_title">退改规则</div>
                <div class="content" v-html="detail.back"></div>
              </div>
            </van-tab>
            <van-tab
              :title="sconfig.title"
              v-if="sconfig.name == 'notice' && sconfig.status"
              ><div class="box">
                <div class="box_title">注意事项</div>
                <div class="content" v-html="detail.notice"></div>
              </div>
            </van-tab>
            <van-tab
              :title="sconfig.title"
              v-if="sconfig.name == 'sesi' && sconfig.status"
            >
              <div class="box">
                <div class="box_title">游轮设施</div>
                <div class="content">
                  <van-row
                    gutter="20"
                    v-if="detail.boat_info && detail.boat_info.sesi.length"
                  >
                    <van-col
                      span="12"
                      v-for="(item, i) in detail.boat_info.sesi"
                      :key="i"
                    >
                      <div class="sesi_item">
                        <div class="thumb" @click="viewImgs(i)">
                          <van-image
                            fit="cover"
                            position="center"
                            :src="
                              item.url +
                              '?x-oss-process=image/resize,m_mfit,w_120,h_120'
                            "
                            style="height: 100%"
                          >
                            <template v-slot:loading>
                              <van-loading type="spinner" size="20" />
                            </template>
                          </van-image>
                        </div>
                        <div class="title">{{ item.name.split(".")[0] }}</div>
                      </div>
                    </van-col>
                  </van-row>
                  <div class="not" v-else>数据整理中</div>
                </div>
              </div>
            </van-tab>
            <van-tab
              :title="sconfig.title"
              v-if="sconfig.name == 'service' && sconfig.status"
            >
              <div class="box">
                <div class="box_title">游轮服务</div>
                <div
                  class="content content_sp"
                  v-if="detail.boat_info.service != ''"
                  v-html="detail.boat_info.service"
                ></div>
                <div class="not" v-else>没有填写</div>
              </div>
            </van-tab>
            <van-tab
              :title="sconfig.title"
              v-if="sconfig.name == 'activity' && sconfig.status"
            >
              <div class="box">
                <div class="box_title">游轮活动</div>
                <div
                  class="content content_sp"
                  v-if="detail.boat_info.activity != ''"
                  v-html="detail.boat_info.activity"
                ></div>
                <div class="not" v-else>没有填写</div>
              </div>
            </van-tab>
            <van-tab
              :title="sconfig.title"
              v-if="sconfig.name == 'food' && sconfig.status"
            >
              <div class="box">
                <div class="box_title">游轮美食</div>
                <div
                  class="content content_sp"
                  v-if="detail.boat_info.food != ''"
                  v-html="detail.boat_info.food"
                ></div>
                <div class="not" v-else>没有填写</div>
              </div>
            </van-tab>
          </div>
          <!-- 以下为自定义展示内容 -->
          <template v-if="detail.diy_content">
            <van-tab
              v-for="(diy, dx) in detail.diy_content"
              :title="diy.title ? diy.title : '标题为空'"
              :key="dx"
            >
              <div class="box">
                <div class="box_title">
                  {{ diy.title ? diy.title : "标题为空" }}
                </div>
                <div class="content content_sp" v-html="diy.content"></div>
              </div>
            </van-tab>
          </template>
        </van-tabs>
      </div>
    </template>
    <in-load v-else />
    <!-- 查看房型 -->
    <van-popup
      v-model:show="viewRoom"
      round
      position="bottom"
      :style="{ height: '560px' }"
    >
      <div class="view_room" ref="view_room" v-if="viewRoom">
        <div class="title">{{ tmpRoomInfo.title }}</div>
        <div class="imgs">
          <van-swipe :autoplay="1000000" lazy-render>
            <van-swipe-item v-if="tmpRoomInfo.video">
              <video
                ref="myPlayer"
                :src="tmpRoomInfo.video"
                controls
                t7-video-player-ext="true"
                webkit-playsinline="true"
                x5-playsinline="true"
                style="width: 100%; height: 200px"
              ></video>
            </van-swipe-item>
            <van-swipe-item v-for="(item, i) in tmpRoomInfo.imgs" :key="i">
              <van-image
                fit="cover"
                position="center"
                :src="
                  item.url + '?x-oss-process=image/resize,m_mfit,w_300,h_300'
                "
                style="width: 100%; height: 200px"
              ></van-image>
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="cansu">
          <div
            class="item"
            v-for="(item, idx) in tmpRoomInfo.content"
            :key="idx"
          >
            {{ item.title }} : {{ item.value }}
          </div>
        </div>
        <div style="margin: 20px; text-align: center">
          <van-button @click="makeOrderOnly" type="primary" block
            >立即预订</van-button
          >
        </div>
      </div>
    </van-popup>

    <!-- 直接预订 -->
    <van-popup
      v-model:show="viewMakeOrder"
      round
      position="bottom"
      :style="{ height: '500px' }"
    >
      <div class="makeOrderOne" v-if="viewMakeOrder">
        <div class="title">选择产品</div>
        <div class="select_box">
          <div class="select_title">
            选择航线<small><van-icon name="info-o" />左右滑动展示更多</small>
          </div>
          <div class="select_con">
            <div class="items">
              <van-tag
                class="item"
                type="primary"
                :plain="line.id != sid"
                v-for="(line, idx) in detail.lineArr"
                @click="selectLine(line.id)"
                :key="idx"
              >
                {{ line.matou.up_info.title }}({{
                  line.matou.up_info.times
                }})<van-icon name="arrow" />{{ line.matou.down_info.title }}({{
                  line.matou.down_info.times
                }})
              </van-tag>
            </div>
          </div>
        </div>
        <div class="select_box">
          <div class="select_title">
            出行日期<small><van-icon name="info-o" />左右滑动展示更多</small>
          </div>
          <div class="select_con">
            <div class="items" v-if="detail.dateArr">
              <van-tag
                class="item"
                :plain="day.date != date"
                @click="selectDay(idx)"
                type="primary"
                v-for="(day, idx) in detail.dateArr"
                :key="idx"
              >
                {{ day.date.split("-").splice(1).join("-") }}
              </van-tag>
            </div>
            <div class="items" v-else>
              <div
                style="
                  width: 100%;
                  text-align: center;
                  font-size: 1rem;
                  color: #666;
                "
              >
                没有记录
              </div>
            </div>
          </div>
        </div>
        <div class="select_box">
          <div class="select_title">
            选择房型<small><van-icon name="info-o" />左右滑动展示更多</small>
          </div>
          <div class="select_con">
            <div class="items" v-if="roomArr.room_list">
              <template v-for="(item, idx) in roomArr.room_list">
                <van-tag
                  class="item"
                  style="padding: 8px; text-align: center"
                  :plain="roomData && item.id == roomData.id ? false : true"
                  @click="selectRoom(item)"
                  type="primary"
                  v-if="parseInt(item.price_list[0].value) > 0"
                  :key="idx"
                >
                  {{ item.title }} <br />￥{{ item.price_list[0].value }}
                </van-tag>
              </template>
            </div>
            <div class="items" v-else>
              <div
                style="
                  width: 100%;
                  text-align: center;
                  font-size: 1rem;
                  color: #666;
                "
              >
                没有记录
              </div>
            </div>
          </div>
        </div>
        <div style="margin: 20px; text-align: center">
          <van-button @click="makeOrderTwo" type="primary" block
            >下一步</van-button
          >
        </div>
      </div>
    </van-popup>

    <van-action-bar class="ftbtn" v-if="detail">
      <van-action-bar-icon icon="wap-home-o" @click="goUrl('/')" text="首页" />
      <van-action-bar-icon
        icon="phone-o"
        @click="goUrl(detail.phone)"
        color="#ff0000"
        text="电话咨询"
      />
      <nut-animate type="breath" loop>
        <van-action-bar-icon
          icon="chat-o"
          @click="goUrl(detail.chat_url)"
          color="#ff0000"
          text="在线咨询"
        />
      </nut-animate>
      <van-action-bar-button
        @click="beforeMakeOrder"
        type="warning"
        text="立即预订"
      />
    </van-action-bar>
    <my-footer :view="hasLoad" :showElse="false" :showBar="false"></my-footer>
    <share-box v-if="loadShare" :shareData="shareData"></share-box>
  </div>
</template>
<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import MyFooter from "@/components/myFooter.vue";

import { showToast, showImagePreview } from "vant";
import RoomInfo from "@/components/RoomInfo.vue";
import ShareBox from "@/components/ShareBox.vue";
import InLoad from "@/components/InLoad.vue";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
export default {
  name: "detail",
  components: {
    RoomInfo,
    MyFooter,
    ShareBox,
    InLoad,
  },
  data() {
    return {
      tab_act: -1,
      tmpArr: [],
      hasLoad: false,
      loading: false,
      atype: false,
      viewBoatVideo: false,
      viewRoom: false,
      tmpRoomInfo: false,
      viewMakeOrder: false,
      tabActive: 0,
      sid: 0,
      detail: false,
      roomArr: false,
      dateIdx: 0,
      roomData: false,
      orderData: false,
      active: 0,
      date: false,
      pro: false, //区分显示航线或游船信息
      room_tips: false,
      loadShare: false,
      shareData: false,
    };
  },
  setup() {
    // const router = useRouter();
    // router.beforeEach((to, from) => {
    //   if (to.hash) {
    //     const element = document.querySelector(to.hash);
    //     if (element) {
    //         element.scrollIntoView({ behavior: 'smooth' })
    //     }
    //     return
    //   }
    // });
    const onClickLeft = () => history.back();
    return {
      onClickLeft,
    };
  },
  mounted() {
    // 模型
    this.atype =
      this.$route.params.type != null && this.$route.params.type
        ? this.$route.params.type
        : false;
    // ID
    this.sid =
      this.$route.params.sid != null && this.$route.params.sid
        ? this.$route.params.sid
        : false;
    this.date =
      this.$route.params.date != null && this.$route.params.date
        ? this.$route.params.date
        : false;

    if (!this.atype || !this.sid) {
      let _this = this;
      showToast("非法请求");
      setTimeout(function () {
        _this.goUrl("/");
      }, 100);
      return;
    }
    localStorage.removeItem("tmpData");
    this.room_tips = localStorage.getItem("room_tips");
    this.getDetail();
  },
  methods: {
    viewImgs(idx = 0) {
      let arr = this.detail.boat_info.sesi.map((item) => {
        return item.url + "?x-oss-process=image/resize,m_mfit,w_300,h_300";
      });
      if (arr.length == 0) {
        showToast("没有数据");
        return;
      }
      showImagePreview({
        images: arr,
        startPosition: idx,
      });
    },
    onClickRight() {
      this.$router.push("/");
    },
    makeOrderTwo() {
      if (!this.roomData) {
        showToast("请选择房型");
        return;
      }
      // 组合订单数据
      this.orderData = {
        lid: this.detail.id,
        bid: this.detail.bid,
        rid: this.roomData.id,
        rtitle: this.roomData.title,
        ltitle: this.detail.title,
        btitle: this.detail.btitle,
        thumb: this.detail.boat_info.thumb,
        date: this.date,
        room: this.roomData,
      };

      localStorage.setItem("tmpData", JSON.stringify(this.orderData));
      this.$router.push({ path: "/makeOrder" });
    },
    makeOrderOnly() {
      this.viewRoom = false;
      this.selectRoom(this.tmpRoomInfo);
      this.makeOrderTwo();
    },
    beforeMakeOrder() {
      if (!this.roomArr) {
        showToast("所选航线没有出行计划");
        return;
      }
      showToast("直接选择房型价格即可");
      return;
      this.viewMakeOrder = true;
      this.roomData = false;
    },
    viewRoomInfo(room) {
      if (!parseInt(room.price_list[0].value) > 0) {
        return;
      }
      this.viewRoom = true;
      this.tmpRoomInfo = room;
    },
    selectLine(id) {
      // window.location.href = "/line/detail/" + id;
      // return;
      // this.$router.push({
      //   name: "detail",
      //   params: {
      //     type: "line",
      //     sid: id,
      //   },
      // });
      this.sid = id;
      this.date = false;
      this.roomData = false;
      this.getDetail();
    },
    selectDay(idx) {
      this.dateIdx = idx;
      this.roomArr = this.detail.dateArr[idx];
      this.roomData = false;
      this.date = this.roomArr.date;
    },
    selectRoom(item) {
      let tmp = JSON.parse(JSON.stringify(item));
      delete tmp.content;
      delete tmp.video;
      delete tmp.imgs;
      this.roomData = tmp;
    },
    checkRcs(val) {
      let arr = ["结构", "面积", "入住人数"];
      let tit = false;
      arr.forEach((item) => {
        if (val.indexOf(item) != -1) {
          tit = item;
          return;
        }
      });
      return tit;
    },
    litenScrollTop() {
      let _this = this;
      setTimeout(() => {
        document.querySelectorAll("img").forEach((item) => {
          item.style.width = "100%";
        });
      }, 500);
    },
    scrollToAnchor(val) {
      const targetElement = document.querySelector("#tab" + val);
      // targetElement.scrollIntoView({ behavior: 'smooth' })
      window.scrollTo({
        top: targetElement.offsetTop - 40,
        behavior: "smooth",
      });
    },
    scrollToTop() {
      var curScrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      let flag = true;
      const len = this.tmpArr.length;
      for (let i = len - 1; i >= 0; i--) {
        const curReference = this.tmpArr[i].top; // 当前参考值
        if (flag && curScrollTop >= curReference - 50) {
          flag = false;
          this.tab_act = i;
          return;
        }
      }
      // this.tmpArr.forEach((item,idx)=>{
      //     if(item.top >= scrollTop){
      //         this.tab_act = idx+1
      //         console.log(item.top,scrollTop)
      //     }
      // })
    },
    openApps() {
      this.viewApps = true;
    },
    goUrl(url = false) {
      if (!url || url == "") {
        showToast("暂未启用");
        return;
      }
      if (url.indexOf("tell") >= 0) {
        this.callPhone(url.split(":")[1]);
        return;
      }
      if (url.indexOf("http") >= 0) {
        window.location.href = url;
        return;
      }
      this.$router.push({ path: url });
    },
    callPhone(phoneNumber = false) {
      if (!phoneNumber) {
        showToast("暂未启用");
        return;
      }
      window.location.href = "tel://" + phoneNumber;
    },
    getDetail() {
      this.loading = true;
      this.$http
        .getDetail({ table: "Bline", id: this.sid, date: this.date })
        .then((res) => {
          this.detail = res;
          if (this.atype == "boat") {
            // 入口为游船
            this.pro = {
              title: res.boat_info.title,
              nav_title: res.boat_info.title,
              imgs: res.boat_info.imgs,
              thumb: res.boat_info.thumb,
            };
          }
          if (this.atype == "line") {
            // 入口为航线
            this.pro = {
              title: res.title,
              nav_title: res.title,
              imgs: res.imgs,
              thumb: res.thumb,
            };
          }
          this.detail.show_config.map((item) => {
            if (item.title == "行程") {
              item.title = "行程安排";
            } else if (item.title == "设施") {
              item.title = "游轮设施";
            } else if (item.title == "游船活动") {
              item.title = "游轮活动";
            } else if (item.title == "游船美食") {
              item.title = "游轮美食";
            } else if (item.title == "游船服务") {
              item.title = "游轮服务";
            } else if (item.title == "游船设施") {
              item.title = "游轮设施";
            } else if (item.title == "游船介绍") {
              item.title = "游轮介绍";
            }
          });
          // 处理当前选项
          this.roomArr = res.dateArr.length > 0 ? res.dateArr[0] : false;
          this.room_id = 0;
          this.date = res.date;
          this.litenScrollTop();
          // 同步更新页面标题
          document.title = res.title + "|详情";
          this.shareData = {
            title: res.title,
            desc: res.share_desc ? res.share_desc : res.stitle,
            link: false, // 默认获取当前页面 要分享的页面地址不允许存在#参数
            imgUrl:
              process.env.VUE_APP_HOST +
              res.thumb +
              "?x-oss-process=image/resize,m_mfit,w_120,h_120", // 分享出去链接图片
          };
          this.loadShare = true;
          this.loading = false;
          this.hasLoad = true;
        })
        .catch((error) => {
          showToast("出错了");
          setTimeout(function () {
            history.back();
            this.loading = true;
          }, 1500);
        });
    },
  },
};
</script>
  <style scoped>
.hello {
  min-height: 100vh;
  padding-bottom: 80px;
}
.not {
  text-align: center;
  color: #999;
  font-size: 1rem;
  min-height: 60px;
  line-height: 60px;
  background-color: #fff;
}
.page_title {
  height: 40px;
  line-height: 40px;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
  background: rgba(49, 151, 253, 1);
  color: #ffffff;
  text-align: center;
}
.room_tips {
  margin: 10px;
  padding-bottom: 10px;
}
.room_tips .title {
  font-size: 1.2rem;
}
.room_tips .info {
  line-height: 2rem;
  font-size: 1rem;
  color: #666666;
}
.view_room {
}
.view_room .title {
  height: 3.2rem;
  line-height: 3.2rem;
  text-align: center;
  font-size: 1.2rem;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.3);
}
.view_room .imgs {
  margin: 10px;
}
.view_room .imgs .thumb {
  width: 100%;
}
.view_room .cansu {
  margin: 0 20px;
  line-height: 2.5rem;
  font-size: 11.2rem;
}
.view_room .cansu {
  margin: 0 10px;
}
.view_room .cansu .item {
  font-size: 1rem;
  line-height: 2rem;
  color: #666;
}
.item_cs {
  background: #f3f3f3;
  font-size: 1rem;
  line-height: 2rem;
  margin-bottom: 6px;
  padding-left: 8px;
  color: #666;
}
.roomBox {
  background: #ffffff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  font-size: 1.2rem;
}
.roomBox .rtitle {
  line-height: 40px;
  height: 40px;
  font-size: 1.4rem;
  font-weight: 400;
  color: #333333;
  background: #ffffff;
  border-bottom: 1px solid #f3f3f3;
  padding: 0 20px;
  position: relative;
}
.roomBox .rtitle::before {
  position: absolute;
  content: "";
  left: 12px;
  top: 50%;
  width: 2px;
  height: 14px;
  margin-top: -7px;
  background-color: #1989fa;
}

.roomBox .item {
  margin: 1px 0;
  height: 100px;
  position: relative;
  background: #ffffff;
  display: flex;
  border-bottom: 1px solid #f3f3f3;
}
.roomBox .item .thumb {
  border-radius: 5px;
  overflow: hidden;
  width: 80px;
  height: 80px;
  margin-top: 10px;
  margin-left: 10px;
}
.roomBox .item .con {
  padding: 10px;
  padding-left: 30px;
}
.roomBox .item .con .title {
  font-size: 1.2rem;
  color: #333333;
}
.roomBox .item .con .rights {
  position: absolute;
  right: 10px;
  top: 20px;
  font-size: 1rem;
  display: block;
  text-align: right;
  color: #666;
}
.roomBox .item .con .views {
  color: rgba(49, 151, 253, 1);
  padding: 10px 0;
}
.roomBox .item .con .price b {
  color: #ff5400;
  font-size: 1.2rem;
  font-weight: 300;
}
.roomBox .item .cs {
  line-height: 20px;
  font-size: 1rem;
  color: #666666;
}

.headbox {
  position: relative;
  min-height: 300px;
  background: #ffffff;
  /* box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.3); */
}
.headbox .htm {
  padding: 10px 20px;
}
.headbox .title {
  color: #000000;
}
.headbox .title b {
  font-size: 1.5rem;
  color: #333333;
  line-height: 2.4rem;
  font-weight: 500;
}
.headbox .stitle {
  font-size: 1.2rem;
  color: #666;
}
.headbox .infos {
  font-size: 1rem;
  padding: 6px 0;
}
.headbox .infos .item {
  color: #666666;
  height: 2rem;
  line-height: 2rem;
  padding: 0 6px;
  background: #f3f3f3;
  border-radius: 5px;
  margin: 4px 0;
}
.headbox .infos span {
  color: #1989fa;
}
.headbox .notice {
  font-size: 1rem;
  color: #bc5a29;
  line-height: 2rem;
  padding-bottom: 10px;
}
.headbox .price {
  font-size: 2rem;
  color: #ff5400;
  line-height: 3rem;
  font-weight: 500;
}
.headbox small {
  font-size: 1.2rem;
}
.topMain {
  margin: 20px auto;
}
.topMain .select_box {
  margin: 0;
  margin-bottom: 10px;
  /* box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.3); */
}
.topMain .select_title small {
  font-size: 1rem;
  line-height: 40px;
  color: #666666;
  padding-left: 6px;
}

.topMain .select_title {
  line-height: 40px;
  height: 40px;
  font-size: 1.4rem;
  font-weight: 400;
  color: #333333;
  background: #ffffff;
  border-bottom: 1px solid #f3f3f3;
  padding: 0 20px;
  position: relative;
}
.topMain .select_title::before {
  position: absolute;
  content: "";
  left: 12px;
  top: 50%;
  width: 2px;
  height: 14px;
  margin-top: -7px;
  background-color: #1989fa;
}

.topMain .select_title .icon {
  position: absolute;
  z-index: 1;
  right: 10px;
  font-size: 1rem;
  color: #d2505a;
}
.topMain .select_title .icon span {
  font-size: 1rem;
}

.topMain .select_con {
  padding: 10px;
  padding-bottom: 0px;
  background: #ffffff;
}
.topMain .select_con .items {
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  justify-items: center;
  padding-bottom: 10px;
}
.topMain .select_con .item {
  /* flex: 0 0 auto; */
  margin-right: 6px;
  text-align: center;
  border-radius: 6px;
  display: inline-block;
  border: 1px solid #dddddd;
  padding: 4px 8px;
  font-size: 1rem;
  line-height: 1.2rem;
  background: #fff;
  min-width: 6rem;
}
.topMain .select_con .active {
  border: 1px solid #37aefd;
  color: #37aefd;
  background: #fff;
}

.makeOrderOne {
  margin: 20px;
}
.makeOrderOne .title {
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.makeOrderOne .select_box {
  margin: 0;
  margin-bottom: 20px;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.3);
}
.makeOrderOne .select_title {
  font-size: 1rem;
  height: 40px;
  line-height: 40px;
  background: #f3f3f3;
  padding-left: 10px;
}
.makeOrderOne .select_title small {
  font-size: 0.7rem;
  line-height: 40px;
  color: #666666;
  padding-left: 6px;
}

.makeOrderOne .select_con {
  padding: 10px;
  padding-bottom: 0px;
}
.makeOrderOne .select_con .items {
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  justify-items: center;
  padding-bottom: 10px;
}
.makeOrderOne .select_con .item {
  flex: 0 0 auto;
  margin-right: 5px;
  height: 28px;
}

.xingcheng {
  background: #ffffff;
  font-size: 1.2rem;
}
.xingcheng .item {
  position: relative;
  border-bottom: dashed 1px #3197fd;
  margin-bottom: 10px;
}
.xingcheng .item .title {
  color: #3197fd;
  line-height: 40px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
}
.xingcheng .item .infos {
  display: flex;
  justify-content: first baseline;
  line-height: 2rem;
}
.xingcheng .item .infos .icon {
  color: #3197fd;
  margin-right: 10px;
}
.xingcheng .item .infos span {
  margin: 0 4px;
}
.xingcheng .item .text {
  line-height: 2rem;
  overflow-x: hidden;
}
.xingcheng .item .text p {
  display: inline;
}

.box {
  /* margin: 10px; */
  padding: 0;
  background: #ffffff;
  margin: 10px 0;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
}
.box .box_title {
  line-height: 40px;
  height: 40px;
  font-size: 1.4rem;
  font-weight: 400;
  color: #333333;
  background: #ffffff;
  border-bottom: 1px solid #f3f3f3;
  padding: 0 20px;
  position: relative;
}
.box_title::before {
  position: absolute;
  content: "";
  left: 12px;
  top: 50%;
  width: 2px;
  height: 14px;
  margin-top: -7px;
  background-color: #1989fa;
}
.box .content {
  font-size: 1.2rem;
  line-height: 2rem;
  margin: 0;
  color: #333;
  overflow-x: hidden;
  min-height: 80px;
  padding: 10px;
}
.box .content_sp img {
  width: 100% !important;
}
.box .content p {
  word-wrap: break-word;
}
/* 设施 */
.sesi_item {
  margin-bottom: 10px;
  text-align: center;
}
.sesi_item .thumb {
  height: 120px;
  border-radius: 6px;
  overflow: hidden;
  margin: 0 auto;
}
.sesi_item .title {
  line-height: 2rem;
  text-align: center;
  color: #666;
}
.ftbtn {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 6px;
  border-top: 1px solid #f3f3f3;
}

.van-tab__panel {
  padding: 0 !important;
}
.van-nav-bar {
  height: 43px !important;
  overflow: hidden;
}
.video_box {
  width: 100%;
  height: 240px;
  position: relative;
  overflow: hidden;
}
.video_box .play {
  display: block;
  line-height: 240px;
  text-align: center;
  font-size: 5rem;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
</style>
  