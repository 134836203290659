import { createApp } from 'vue'
import App from './App.vue'
// 1. 引入你需要的组件
import vant from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';

import router from './router'
import apis from '@/libs/apis'

import { library } from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// library.add(faRepeat)
// library.add(faUserSecret)
// library.add(faPlayCircle)
// library.add(faSearchPlus)
library.add(fas)

let app = createApp(App)


import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/style.css";
import { IconFont } from '@nutui/icons-vue'
import '@nutui/icons-vue/dist/style_iconfont.css'

app.config.globalProperties.$http = apis

app.config.globalProperties.$goBacks = function (url='/') {
    if (window.history.length > 1) {
        router.go(-1);
      } else {
        router.push(url);
      }
}

import { showToast } from 'vant';
app.config.globalProperties.$notFound = function(msg='暂未启用'){
    showToast(msg)
}

app.use(router).use(vant).use(NutUI).use(IconFont).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
