<template>
  <div
    class="contaer"
    :class="{
      shad: !hideBorder,
      need_margin: needMargin,
      need_margin_top: needMarginTop,
    }"
  >
    <template v-if="!hideTitle">
      <div class="title">
        <slot name="title">{{ title }}</slot>
      </div>
    </template>
    <div
      class="scontent"
      :style="{
        background: backgroundColor,
      }"
    >
      <slot name="content">{{ content }}</slot>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    title: {
      type: String,
      default: "标题",
    },
    content: {
      type: String,
      default: () => {
        return "暂无内容";
      },
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "#ffffff",
    },
    needMargin: {
      type: Boolean,
      default: true,
    },
    needMarginTop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
  
  <style scoped>
.contaer {
  border-radius: 6px;
}
.need_margin {
  margin: 16px;
}
.need_margin_top {
  margin-top: 16px;
}
.shad {
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.2);
}
.title {
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid #f3f3f3;
}
.scontent {
  background: #ffffff;
}
</style>
  