<template>
  <div v-if="item">
    <!-- 线路A -->
    <div class="pro_line_a" v-if="type == 'line_a'" @click="goUrl(item.url)">
      <van-image
        fit="cover"
        position="center"
        :src="item.thumb + '?x-oss-process=image/resize,m_mfit,w_200,h_140'"
        class="thumb"
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
      <van-tag class="stars" type="warning" v-if="item.star">{{
        item.star
      }}</van-tag>
      <div class="title">
        <van-text-ellipsis rows="1" :content="item.title" position="end" />
      </div>
      <div class="tags" v-if="item.tags.length">
        <van-tag
          type="success"
          plain
          size="mini"
          style="margin: 0 2px"
          round
          v-for="(t, i) in item.tags"
          :key="i"
          >{{ t }}</van-tag
        >
      </div>
      <div class="stitle" v-else>
        <van-text-ellipsis rows="1" :content="item.stitle" position="end" />
      </div>
      <div class="price_info">
        <my-price size="large" :price="item.mins" before="￥" after="起" />
      </div>
    </div>

    <!-- 线路B -->
    <div
      class="pro_line_b"
      v-else-if="type == 'line_b'"
      @click="goUrl(item.url)"
    >
      <div class="item" @click="goUrl(item.url)">
        <van-image
          fit="cover"
          position="center"
          :src="item.thumb + '?x-oss-process=image/resize,m_mfit,w_200,h_140'"
          class="thumb"
        >
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image>

        <van-tag class="stars" type="warning" v-if="item.star">{{
          item.star
        }}</van-tag>

        <div class="right">
          <div class="title">
            <van-text-ellipsis rows="1" :content="item.title" />
          </div>
          <div class="tags" v-if="item.tags.length">
            <van-tag
              type="success"
              plain
              size="mini"
              style="margin: 0 2px"
              round
              v-for="(t, i) in item.tags"
              :key="i"
              >{{ t }}</van-tag
            >
          </div>
          <div class="stitle" style="color: #666666" v-if="item.stitle != ''">
            <van-text-ellipsis rows="1" :content="item.stitle" position="end" />
          </div>
          <div class="price_info">
            <my-price :price="item.mins" size="large" before="￥" after="起" />
          </div>
        </div>
      </div>
    </div>
    <!-- 游船 -->
    <div
      v-else-if="type == 'boat_a'"
      class="pro_boat_a"
      @click="goUrl(item.mins ? item.mins.url : item.url)"
    >
      <van-image
        fit="cover"
        position="center"
        :src="item.thumb + '?x-oss-process=image/resize,m_mfit,w_200,h_140'"
        class="thumb"
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
      <van-tag class="stars" type="warning" v-if="item.star">{{
        item.star
      }}</van-tag>
      <div class="title">
        <van-text-ellipsis rows="1" :content="item.title" position="end" />
      </div>
      <div class="tags" v-if="item.tags.length">
        <van-tag
          type="success"
          plain
          size="mini"
          style="margin: 0 2px"
          round
          v-for="(t, i) in item.tags"
          :key="i"
          >{{ t }}</van-tag
        >
      </div>
      <div class="stitle" v-else>
        <van-text-ellipsis rows="1" :content="item.stitle" position="end" />
      </div>
      <div class="price_info">
        <my-price
          size="large"
          :price="item.mins.price"
          before="￥"
          after="起"
        />
      </div>
    </div>

    <div
      v-else-if="type == 'boat_b'"
      class="pro_boat_b"
      @click="goUrl(item.mins ? item.mins.url : item.url)"
    >
      <van-image
        fit="cover"
        position="center"
        :src="item.thumb + '?x-oss-process=image/resize,m_mfit,w_200,h_140'"
        class="thumb"
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
      <van-tag class="stars" type="warning" v-if="item.star">{{
        item.star
      }}</van-tag>
      <div class="title">
        {{ item.title }}
      </div>
      <div class="tags" v-if="item.tags.length">
        <van-tag
          type="success"
          plain
          size="mini"
          style="margin: 0 2px"
          round
          v-for="(t, i) in item.tags"
          :key="i"
          >{{ t }}</van-tag
        >
      </div>
      <div class="stitle" v-else>
        <van-text-ellipsis rows="1" :content="item.stitle" position="end" />
      </div>
      <div class="price_info">
        <my-price
          size="large"
          :price="item.mins.price"
          before="￥"
          after="起"
        />
      </div>
    </div>
    <!-- 新闻列表 -->
    <div v-else-if="type == 'news_list'" class="pro_news_list">
      <div class="item" @click="goUrl(item.url)">
        <van-image
          :src="item.thumb + '?x-oss-process=image/resize,m_mfit,w_200,h_140'"
          class="thumb"
        ></van-image>
        <div class="right">
          <div class="title">
            <van-text-ellipsis rows="2" :content="item.title" />
          </div>
          <div class="info">
            <span><font-awesome-icon icon="eye" /> {{ item.view }}</span>
            <span><font-awesome-icon icon="clock" /> {{ item.times }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import myPrice from "@/components/myPrice.vue";

export default {
  name: "ProItem",
  components: {
    myPrice,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: "line_a",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goUrl(url) {
      if (!url) return;
      if (url == "not") {
        this.$notFound();
        return;
      }
      if (url.indexOf("http") > -1) {
        window.location.href = url;
        return;
      }
      this.$router.push(url);
    },
  },
};
</script>
  
  <style scoped>
.pro_line_a {
  font-size: 1.2rem;
  margin: 10px 0;
  background: #fff;
  height: 206px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0px 6px 0px rgba(0, 0, 0, 0.2);
}
.pro_line_a .stars {
  position: absolute;
  left: 10px;
  top: 10px;
}
.pro_line_a .tags {
  height: 24px;
  line-height: 2rem;
  overflow: hidden;
}
.pro_line_a .title {
  font-size: 1.2rem;
  line-height: 2rem;
  height: 2rem;
  overflow: hidden;
  padding: 0 10px;
}
.pro_line_a .tags {
  line-height: 2rem;
  height: 2rem;
  overflow: hidden;
}
.pro_line_a .stitle {
  line-height: 2rem;
  height: 2rem;
  overflow: hidden;
  font-size: 1rem;
  color: #999;
}
.pro_line_a .thumb {
  width: 100%;
  height: 117px;
  border-radius: 6px 6px 0px 0px;
  overflow: hidden;
}
.pro_line_a .price_info {
  color: #666;
  padding: 0 10px;
  font-size: 1rem;
  position: absolute;
  left: 0px;
  bottom: 10px;
}

.pro_line_b {
}
.pro_line_b .item {
  position: relative;
  min-height: 120px;
  margin: 10px 0;
  background: #ffffff;
  padding: 10px;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.2);
}
.pro_line_b .thumb {
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 0px;
  height: 120px;
  width: 120px;
  border-radius: 6px 0 0 6px;
  overflow: hidden;
}
.pro_line_b .right {
  padding-left: 127px;
}
.pro_line_b .title {
  font-size: 1.2rem;
  line-height: 2rem;
  height: 2rem;
  overflow: hidden;
}
.pro_line_b .tags {
  line-height: 2rem;
  height: 2rem;
  overflow: hidden;
}
.pro_line_b .stitle {
  line-height: 2rem;
  height: 2rem;
  overflow: hidden;
  font-size: 1rem;
  color: #999;
}
.pro_boat_a {
  width: 185px;
  display: inline-block;
  font-size: 1.2rem;
  background: #fff;
  height: 206px;
  margin-right: 10px;
  border-radius: 6px;
  position: relative;
  box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.2);
}
.pro_boat_a .stars {
  position: absolute;
  left: 10px;
  top: 10px;
}
.pro_boat_a .tags {
  height: 24px;
  line-height: 2rem;
  overflow: hidden;
}
.pro_boat_a .title {
  line-height: 2rem;
  height: 2rem;
  overflow: hidden;
  padding: 0 10px;
}
.pro_boat_a .thumb {
  width: 100%;
  height: 120px;
  border-radius: 6px 6px 0px 0px;
  overflow: hidden;
}
.pro_boat_a .price_info {
  color: #666;
  padding: 0 10px;
  font-size: 1rem;
  position: absolute;
  left: 0px;
  bottom: 10px;
}

.pro_boat_b {
  width: 100%;
  display: inline-block;
  font-size: 1.2rem;
  background: #fff;
  height: 206px;
  border-radius: 6px;
  position: relative;
  box-shadow: 0 0px 6px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
}
.pro_boat_b .stars {
  position: absolute;
  left: 10px;
  top: 10px;
}
.pro_boat_b .tags {
  overflow: hidden;
  line-height: 2rem;
  height: 2rem;
  margin: 0 6px;
}
.pro_boat_b .title {
  line-height: 2rem;
  font-size: 1.2rem;
  height: 2rem;
  overflow: hidden;
  padding: 0 6px;
}
.pro_boat_b .thumb {
  width: 100%;
  height: 120px;
  border-radius: 6px 6px 0px 0px;
  overflow: hidden;
}
.pro_boat_b .price_info {
  color: #666;
  padding: 0 6px;
  font-size: 1rem;
  position: absolute;
  left: 0px;
  bottom: 10px;
}

.pro_news_list {
}
.pro_news_list .item {
  position: relative;
  min-height: 90px;
  margin: 5px 0;
  margin-bottom: 10px;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;
}
.pro_news_list .thumb {
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 0px;
  height: 100px;
  width: 100px;
  border-radius: 5px 0 0 5px;
  overflow: hidden;
}
.pro_news_list .right {
  padding-left: 100px;
}
.pro_news_list .title {
  font-size: 1.2rem;
  line-height: 30px;
  height: 60px;
  overflow: hidden;
}
.pro_news_list .info {
  font-size: 1rem;
  line-height: 20px;
  color: #999999;
  display: flex;
  justify-content: space-between;
}
</style>
  