<template>
    <div class="hello">
        <van-overlay show="true">
          <van-loading size="24px" type="spinner" color="#1989fa" style="margin-top: 10rem;" vertical>正在登陆...</van-loading>
        </van-overlay>
    </div>
</template>
  
<script>
import { showToast } from 'vant';

  export default {
    name: 'Login',
    data(){
        return {
          code:false
        }
    },
    mounted() {
      this.code = this.$route.query.code?this.$route.query.code:false
      let openid = localStorage.getItem('openid')
      if(openid){
        this.$router.replace({path:'/'})
        return
      }
      if(this.code){
        this.toLogin()
      }else{
        showToast('参数错误')
        return
      }
    },
    methods: {
      toLogin(){
        this.$http.login({
          'code':this.code,
        }).then(res=>{
          localStorage.setItem('openid',res)
          let redirect_url = localStorage.getItem('redirect_url')
          if(redirect_url){
            localStorage.removeItem('redirect_url')
            window.location.href = redirect_url
          }
        }).catch(e=>{
          console.log(e)
        })
        
      }
    },
  }
  </script>
  <style scoped>
  </style>
  