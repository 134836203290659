<template>
  <div>
    <van-back-top right="5vw" bottom="20vh" />
    <template v-if="view">
      <template v-if="showElse">
        <div class="links" v-if="footer_links">
          <span
            v-for="(link, i) in footer_links"
            :key="i"
            @click="goUrl(link)"
            >{{ link.title }}</span
          >
        </div>
        <div class="copyright dibb" v-if="copyright" v-html="copyright"></div>
        <!-- <div class="pmt">
            <van-image fit="cover" src="/img/alipay.png" class="imm"></van-image>
            <van-image fit="cover" src="/img/wxpay.jpg" style="margin:0 10px;" class="imm"></van-image>
            <van-image fit="cover" src="/img/ysf.jpg" style="margin-right: 10px;" class="imm"></van-image>
            <van-image fit="cover" src="/img/yl.jpg" class="imm" style="width:48px;height:30px"></van-image>
          </div> -->
      </template>
      <van-tabbar
        v-if="showTabBar && tabData"
        :style="tabData.style"
        v-model="active"
        :active-color="tabData.activeColor"
        style="z-index: 10"
      >
        <van-tabbar-item
          :style="tab.style"
          v-for="(tab, idx) in tabData.list"
          :key="idx"
          :name="tab.name"
          :dot="tab.dot"
          :badge="tab.badge"
          @click="toPage(tab)"
        >
          <template #icon>
            <nut-animate v-if="tab.animate" :type="tab.animate" loop>
              <van-icon
                v-if="tab.iconType == 'van'"
                :name="tab.icon"
                :style="{
                  color: tab.icon_color ? tab.icon_color : '',
                  fontSize: tab.icon_size ? tab.icon_size : '',
                  margin: tab.icon_margin ? tab.icon_margin : '',
                }"
              />
              <IconFont
                v-else-if="tab.iconType == 'nut'"
                :name="tab.icon"
                :style="{
                  color: tab.icon_color ? tab.icon_color : '',
                  fontSize: tab.icon_size ? tab.icon_size : '',
                  margin: tab.icon_margin ? tab.icon_margin : '',
                }"
              ></IconFont>
              <font-awesome-icon
                v-else-if="tab.iconType == 'font'"
                :icon="tab.icon"
                :style="{
                  color: tab.icon_color ? tab.icon_color : '',
                  fontSize: tab.icon_size ? tab.icon_size : '',
                  margin: tab.icon_margin ? tab.icon_margin : '',
                }"
              />
            </nut-animate>
            <template v-else>
              <!-- 没有动画 -->
              <van-icon
                v-if="tab.iconType == 'van'"
                :name="tab.icon"
                :style="{
                  color: tab.icon_color ? tab.icon_color : '',
                  fontSize: tab.icon_size ? tab.icon_size : '',
                  margin: tab.icon_margin ? tab.icon_margin : '',
                }"
              />
              <IconFont
                v-else-if="tab.iconType == 'nut'"
                :name="tab.icon"
                :style="{
                  color: tab.icon_color ? tab.icon_color : '',
                  fontSize: tab.icon_size ? tab.icon_size : '',
                  margin: tab.icon_margin ? tab.icon_margin : '',
                }"
              ></IconFont>
              <font-awesome-icon
                v-else-if="tab.iconType == 'font'"
                :icon="tab.icon"
                :style="{
                  color: tab.icon_color ? tab.icon_color : '',
                  fontSize: tab.icon_size ? tab.icon_size : '',
                  margin: tab.icon_margin ? tab.icon_margin : '',
                }"
              />
            </template>
          </template>
          <span
            :style="{
              color: tab.color ? tab.color : '',
              fontSize: tab.size ? tab.size : '',
            }"
            >{{ tab.title }}</span
          >
        </van-tabbar-item>
      </van-tabbar>
    </template>
  </div>
</template>
  
  <script>
export default {
  name: "myFooter",
  props: {
    view: {
      type: Boolean,
      default: false,
    },
    showTabBar: {
      type: Boolean,
      default: false,
    },
    showElse: {
      type: Boolean,
      default: true,
    },
    activeStr: {
      type: String,
      default: "home",
    },
  },
  data() {
    return {
      active: "",
      tabData: false,
      copyright: false,
      footer_links: false,
    };
  },
  mounted() {
    this.copyright = localStorage.getItem("copyright");
    this.footer_links = JSON.parse(localStorage.getItem("footer_links"));
    this.tabData =
      localStorage.getItem("tabData") &&
      localStorage.getItem("tabData") != undefined
        ? JSON.parse(localStorage.getItem("tabData"))
        : false;
    if (!this.tabData || this.tabData == undefined) {
      this.getBars();
    } else {
      console.log(this.tabData);
    }
    this.active = this.activeStr;
  },
  methods: {
    getBars() {
      this.$http
        .postData("/getBars", {
          hide_success: true,
          hide_error: true,
        })
        .then((res) => {
          localStorage.setItem("tabData", JSON.stringify(res.bars));
          this.tabData = res.bars;
        })
        .catch((error) => {
          this.tabData = false;
        });
    },
    toPage(tab) {
      this.active = "";
      if (tab.url == "not") {
        this.$notFound("暂未启用");
        return;
      }
      if (tab.url.indexOf("tel:") >= 0) {
        window.location.href = "tel://" + tab.url.split(":")[1];
        return;
      }
      if (tab.url.indexOf("http") >= 0) {
        window.location.href = tab.url;
        return;
      }
      this.$router.push({ path: tab.url });
    },
    goUrl(tab) {
      if (tab.value == "not") {
        this.$notFound("暂未启用");
        return;
      }
      if (tab.value.indexOf("tell") >= 0) {
        window.location.href = "tel://" + tab.value.split(":")[1];
        return;
      }
      if (tab.value.indexOf("http") >= 0) {
        window.location.href = tab.value;
        return;
      }
      this.$router.push({ path: tab.value });
    },
  },
};
</script>
  
  <style scoped>
.links {
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  margin-top: 30px;
}
.links span {
  margin: 0 6px;
}
.copyright {
  line-height: 20px;
  font-size: 12px;
  padding: 0 20px;
  clear: both;
  margin: 10px auto;
}
.pmt {
  margin-top: 10px;
  text-align: center;
}
.pmt .imm {
  width: 30px;
  height: 30px;
}
</style>
  