import request from './http'

const apis = {
    sendData: (data) => {
        data = !data ? {} : data
        const url = '/test'
        return request({
            url: url,
            method: 'post',
            data
        })
    }, login: (data) => {
        data = !data ? {} : data
        const url = '/Auth/loginByCode'
        return request({
            url: url,
            method: 'post',
            data,
            hide_loading: true
        })
    }, loginByPhone: (data) => {
        data = !data ? {} : data
        const url = '/Auth/loginByPhone'
        return request({
            url: url,
            method: 'post',
            data,
            hide_loading: true
        })
    },searchOrder: (data) => {
        data = !data ? {} : data
        const url = '/Order/searchOrder'
        return request({
            url: url,
            method: 'post',
            data,
            hide_loading: true
        })
    }, makeShareUrl: (data) => {
        data = !data ? {} : data
        const url = '/Auth/makeShareUrl'
        return request({
            url: url,
            method: 'post',
            data,
            hide_loading: true,
            hide_success: true
        })
    }, bindParent: (data) => {
        data = !data ? {} : data
        const url = '/User/bindParent'
        return request({
            url: url,
            method: 'post',
            data,
            hide_loading: true,
            hide_error: true
        })
    }, getDetail: (data) => {
        data = !data ? {} : data
        const url = `/${data.table}/getDetail`
        return request({
            url: url,
            method: 'post',
            data,
            hide_success: true
        })
    }, getList: (data) => {
        data = !data ? {} : data
        const url = `/${data.table}/getList`
        return request({
            url: url,
            method: 'post',
            data,
            hide_loading: data.hide_loading ? data.hide_loading : false,
            hide_success: data.hide_success != null ? data.hide_success : false,
            hide_error: data.hide_error ? data.hide_error : false,
        })
    }, answerQuestion: (data) => {
        data = !data ? {} : data
        const url = '/Question/answerQuestion'
        return request({
            url: url,
            method: 'post',
            data
        })
    }, sendComment: (data) => {
        data = !data ? {} : data
        const url = '/Comment/sendComment'
        return request({
            url: url,
            method: 'post',
            data,
            hide_loading: data.hide_loading ? data.hide_loading : false,
            hide_success: data.hide_success != null ? data.hide_success : false,
            hide_error: data.hide_error ? data.hide_error : false,
        })
    }, postData: (url, data) => {
        data = !data ? {} : data
        return request({
            url: url,
            method: 'post',
            data: data,
            hide_loading: data.hide_loading ? data.hide_loading : false,
            hide_success: data.hide_success != null ? data.hide_success : true,
            hide_error: data.hide_error ? data.hide_error : false,
        })
    }
}

export default apis